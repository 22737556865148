export const SVG_ICONS = [
    {
        key: 'analytic',
        path: '../assets/images/icons/analytic.svg'
    },
    {
        key: 'user-module',
        path: '../assets/images/icons/user-module.svg'
    },
    {
        key: 'business-unit',
        path: '../assets/images/icons/business-unit.svg'
    },
    {
        key: 'picture',
        path: '../assets/images/icons/picture.svg'
    },
    {
        key: 'permission',
        path: '../assets/images/icons/permission.svg'
    },
    {
        key: 'organization',
        path: '../assets/images/icons/organization.svg'
    },
    {
        key: 'filter',
        path: '../assets/images/icons/filter.svg'
    },
    {
        key: 'inbox',
        path: '../assets/images/icons/inbox.svg'
    },
    {
        key: 'company',
        path: '../assets/images/icons/company.svg'
    },
    {
        key: 'individual',
        path: '../assets/images/icons/individual.svg'
    },
    {
        key: 'item-management',
        path: '../assets/images/icons/item-management.svg'
    },
    {
        key: 'add-user',
        path: '../assets/images/icons/add-user.svg'
    },
    {
        key: 'price-book',
        path: '../assets/images/icons/price-book.svg'
    },
    {
        key: 'price-book-active',
        path: '../assets/images/icons/price-book-active.svg'
    },
    {
        key: 'price-book-inactive',
        path: '../assets/images/icons/price-book-inactive.svg'
    },
    {
        key: 'price-book-id',
        path: '../assets/images/icons/price-book-id.svg'
    },
    {
        key: 'empty',
        path: '../assets/images/icons/illustrations/empty.svg'
    },
    {
        key: 'document-template',
        path: '../assets/images/icons/document-template.svg'
    },
    {
        key: 'document',
        path: '../assets/images/icons/document.svg'
    },
    {
        key: 'view-grid',
        path: '../assets/images/icons/view-grid.svg'
    },
    {
        key: 'view-list',
        path: '../assets/images/icons/view-list.svg'
    },
    {
        key: 'form-mode',
        path: '../assets/images/icons/form-mode.svg'
    },
    {
        key: 'add-table',
        path: '../assets/images/icons/add-table.svg'
    },
    {
        key: 'image',
        path: '../assets/images/icons/image.svg'
    },
    {
        key: 'page-break',
        path: '../assets/images/icons/page-break.svg'
    },
    {
        key: 'text',
        path: '../assets/images/icons/text.svg'
    },
    {
        key: 'embed',
        path: '../assets/images/icons/embed.svg'
    },
    {
        key: 'note',
        path: '../assets/images/icons/note.svg'
    },
    {
        key: 'people',
        path: '../assets/images/icons/people.svg'
    },
    {
        key: 'statistic',
        path: '../assets/images/icons/statistic.svg'
    },
    {
        key: 'bold',
        path: '../assets/images/icons/bold.svg'
    },
    {
        key: 'italic',
        path: '../assets/images/icons/italic.svg'
    },
    {
        key: 'underline',
        path: '../assets/images/icons/underline.svg'
    },
    {
        key: 'floor-plan',
        path: '../assets/images/icons/floor-plan.svg'
    },
    {
        key: 'active-circle',
        path: '../assets/images/icons/active-circle.svg'
    },
    {
        key: 'inactive-circle',
        path: '../assets/images/icons/inactive-circle.svg'
    },
    {
        key: 'check-circle',
        path: '../assets/images/icons/check-circle.svg'
    },
    {
        key: 'done',
        path: '../assets/images/icons/illustrations/done.svg'
    },
    {
        key: 'warning',
        path: '../assets/images/icons/error-v3.svg'
    },
    {
        key: 'signature',
        path: '../assets/images/icons/signature.svg'
    },
    {
        key: 'profile',
        path: '../assets/images/icons/profile.svg'
    },
    {
        key: 'lock',
        path: '../assets/images/icons/lock.svg'
    },
    {
        key: 'building',
        path: '../assets/images/icons/building.svg'
    },
    {
        key: 'erase',
        path: '../assets/images/icons/erase.svg'
    },
    {
        key: 'deal-table',
        path: '../assets/images/icons/deal-table.svg'
    },
    {
        key: 'group-permission',
        path: '../assets/images/icons/group-permission.svg'
    },
    {
        key: 'hourglass',
        path: '../assets/images/icons/hourglass.svg'
    },
    {
        key: 'payment',
        path: '../assets/images/icons/payment.svg'
    },
    {
        key: 'push_notification',
        path: '../assets/images/icons/push_notification.svg'
    },
    {
        key: 'invoice',
        path: '../assets/images/icons/invoice.svg'
    },
    {
        key: 'dollar_coin',
        path: '../assets/images/icons/dollar-coin.svg'
    },
    {
        key: 'paper_money',
        path: '../assets/images/icons/paper-money.svg'
    },
    {
        key: 'webhook',
        path: '../assets/images/icons/webhook.svg'
    },
    {
        key: 'case-submission',
        path: '../assets/images/icons/case-submission.svg'
    },
    {
        key: 'terminal',
        path: '../assets/images/icons/terminal.svg'
    },
    {
        key: 'ticket',
        path: '../assets/images/icons/ticket.svg'
    },
    {
        key: 'duration',
        path: '../assets/images/icons/duration.svg'
    },
    {
        key: 'money',
        path: '../assets/images/icons/money.svg'
    },
    {
        key: 'deposit-amount',
        path: '../assets/images/icons/deposit-amount.svg'
    },
    {
        key: 'submission-requirement',
        path: '../assets/images/icons/submission-requirement.svg'
    },
    {
        key: 'my-case',
        path: '../assets/images/icons/my-case.svg'
    },
    {
        key: 'generate-invoice',
        path: '../assets/images/icons/generate-invoice.svg'
    },
    {
        key: 'feedback',
        path: '../assets/images/icons/feedback.svg'
    },
    {
        key: 'feedback-1',
        path: '../assets/images/icons/feedback-1.svg'
    },
    {
        key: 'access-control',
        path: '../assets/images/icons/access-control.svg'
    },
    {
        key: 'total-volume',
        path: '../assets/images/icons/total-volume.svg'
    },
    {
        key: 'total-deals',
        path: '../assets/images/icons/total-deals.svg'
    },
    {
        key: 'total-commission',
        path: '../assets/images/icons/total-commission.svg'
    },
    {
        key: 'info-outline',
        path: '../assets/images/icons/info-outline.svg'
    },
    {
        key: 'commission-outstanding',
        path: '../assets/images/icons/commission-outstanding.svg'
    },
    {
        key: 'tax-amount',
        path: '../assets/images/icons/tax-amount.svg'
    },
    {
        key: 'member-group',
        path: '../assets/images/icons/member-group.svg'
    },
    {
        key: 'payment-setting',
        path: '../assets/images/icons/payment-setting.svg'
    },
    {

        key: 'invoice-setting',
        path: '../assets/images/icons/invoice-setting.svg'
    },
    {
        key: 'activity-log',
        path: '../assets/images/icons/activity-log.svg'
    },
    {
        key: 'download',
        path: '../assets/images/icons/download.svg'
    },
    {
        key: 'forward',
        path: '../assets/images/icons/forward.svg'
    },
    {
        key: 'approval',
        path: '../assets/images/icons/approval.svg'
    },
    {
        key: 'rated-star',
        path: '../assets/images/icons/rated-star.svg'
    },
    {
        key: 'star',
        path: '../assets/images/icons/star.svg'
    },
    {
        key: 'co-broke',
        path: '../assets/images/icons/co-broke.svg'
    },
    {
        key: 'send',
        path: '../assets/images/icons/send.svg'
    },
    {
        key: 'alarm',
        path: '../assets/images/icons/alarm.svg'
    },
    {
        key: 'google-calendar',
        path: '../assets/images/icons/google-calendar.svg'
    },
    {
        key: 'zoom',
        path: '../assets/images/icons/zoom.svg'
    },
    {
        key: 'google-drive',
        path: '../assets/images/icons/google-drive.svg'
    },
    {
        key: 'microsoft-teams',
        path: '../assets/images/icons/microsoft-teams.svg'
    },
    {
        key: 'whatsapp',
        path: '../assets/images/icons/whatsapp.svg'
    },
    {
        key: 'commission-split-matrix',
        path: '../assets/images/icons/commission-split-matrix.svg'
    },
    {
        key: 'promotion-settings',
        path: '../assets/images/icons/promotion-settings.svg'
    },
    {
        key: 'hierarchy',
        path: '../assets/images/icons/hierarchy.svg'
    }, {
        key: 'commission-distribution',
        path: '../assets/images/icons/commission-distribution.svg'
    }, {
        key: 'card-default',
        path: '../assets/images/icons/card-default.svg'
    },
    {
        key: 'kyc',
        path: '../assets/images/icons/kyc.svg'
    },
    {
        key: 'menu',
        path: '../assets/images/icons/menu.svg'
    },
    {
        key: 'ecdd',
        path: '../assets/images/icons/ecdd.svg'
    },
    {
        key: 'country',
        path: '../assets/images/icons/country.svg'
    },
    {
        key:'general',
        path: '../assets/images/icons/general.svg'
    },
    {
        key:'stylus_note',
        path:'../assets/images/icons/stylus_note.svg'
    },
    {
        key:'dynamic_field_add',
        path:'../assets/images/icons/dynamic_field_add.svg'
    },
    {
        key:'share',
        path:'../assets/images/icons/share.svg'
    },
    {
        key:'use-info',
        path:'../assets/images/icons/use-info.svg'
    },
    {
        key:'finance_chip',
        path:'../assets/images/icons/finance_chip.svg'
    },
    {
        key:'source_environment',
        path:'../assets/images/icons/source_environment.svg'
    },
    {
        key:'receipt_long',
        path:'../assets/images/icons/receipt_long.svg'
    },
    {
        key:'commission_settings',
        path:'../assets/images/icons/commission_settings.svg'
    },
    {
        key:'activities',
        path:'../assets/images/icons/activities.svg'
    },
    {
        key:'editable_doc',
        path:'../assets/images/icons/editable_doc.svg'
    },
    {
        key:'form_mode_doc',
        path:'../assets/images/icons/form_mode_doc.svg'
    },
    {
        key:'leaderboard',
        path:'../assets/images/icons/leaderboard.svg'
    },
    {
        key:'property_info',
        path:'../assets/images/icons/property_info.svg'
    },
    {
        key:'reports',
        path:'../assets/images/icons/reports.svg'
    },
    {
        key:'export_csv',
        path:'../assets/images/icons/exports/export-csv.svg'
    },
    {
        key:'export_pdf',
        path:'../assets/images/icons/exports/export-pdf.svg'
    },
    {
        key:'export_xlsx',
        path:'../assets/images/icons/exports/export-xlsx.svg'
    },
    
    
];
