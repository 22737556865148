import { formatDistanceToNow, isDate, isValid ,differenceInSeconds, differenceInMinutes, differenceInHours, differenceInDays,formatDuration, intervalToDuration} from "date-fns";
export interface IFormatDistanceToNowOptions{
    includeSeconds?:boolean;
    addSuffix?:boolean
}
export interface IFormatDurationOptions{

}
// dont export class, using singeton pattern
class DateTimeFormatterUtils{
    formatDistanceToNow(date:Date,options?:IFormatDistanceToNowOptions):string{
        return formatDistanceToNow(date,options)
    }
    isDate(value:any):boolean{
        return isDate(value)
    }
    isValid(value):boolean{
        if(this.isDate(value)){
            return isValid(value)
        }
        return false;
    }
    nowToDistanceInSeconds(date:Date):number{
        return differenceInSeconds(date,Date.now())
    }

    nowToDistanceInMinutes(date:Date):number{
        return differenceInMinutes(Date.now(),date)
    }
    nowToDistanceInHours(date:Date):number{
        return differenceInHours(Date.now(),date)
    }
    nowToDistanceInDays(date:Date):number{
        return differenceInDays(Date.now(),date)
    }

    formatDuration(duration:Duration):string{
        return formatDuration(duration)
    }
    secondsToDuration(seconds:number):Duration {
        return intervalToDuration({ start: 0, end: seconds * 1000 });
      }
}
export const dateTimeUtils = new DateTimeFormatterUtils()