import { BaseModel } from '@app/core/models/base.model';
import { Exclude, Expose, Transform, Type } from 'class-transformer';
import { FieldBinding } from './setting.model';
import { SettingEntityModel } from './setting-entity.model';
import { Default } from '@app/core/decorators/default.decorator';
@Exclude()
export class TemplateMappingValueMatchModel extends BaseModel {
    @Expose()
    @Default({})
    dynamicField: {[x : string]:string};
    @Expose()
    @Default({})
    signatureField: {[x : string]:string};
    @Expose()
    @Default({})
    participantField: {[x : string]:string};
}
@Exclude()
export class TemplateMappingValueModel extends BaseModel {
    @Expose()
    templateId: string;

    @Expose()
    @Default({})
    @Type(()=>TemplateMappingValueMatchModel)
    match: TemplateMappingValueMatchModel;
}
@Exclude()
export class TemplateMappingModel extends BaseModel{
    @Expose()
    keyName: string;

    @Expose()
    displayName: string;

    @Expose()
    description: string;

    @Expose()
    value: TemplateMappingValueModel;
}