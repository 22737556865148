import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { DocumentModel } from "@app/shared/models/document.model";
import { Router } from "@angular/router";
import { SelectTemplateComponent } from "./select-template.component";
import { DocumentTemplateModel } from "@app/shared/models/document-template.model";

@Injectable()
export class SelectTemplateDialogService {
  constructor(private dialog: MatDialog, private router: Router) {}
  openDialog(afterClosed?: (templateId: number) => void): void {
    const dialogRef = this.dialog.open(SelectTemplateComponent, {
      width: "1180px",
      height: "800px",
      maxWidth: "100%",
      autoFocus: false,
    });
    dialogRef.afterClosed().subscribe((templateId?: number) => {
      console.log('template -->',templateId)
      if (!templateId) {
        return;
      }
      if (afterClosed) {
        afterClosed(templateId);
      } else {
        console.log(templateId);
        this.router.navigate(["/document/create"], {
          queryParams: { templateId: templateId },
        });
      }

      // if (res) {
      //     this.router.navigate(['/document/pdf/' + res.id]);
      // }
    });
  }
}
