import { Component, Inject, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import * as moment from "moment";
import { ActivatedRoute, Router } from "@angular/router";
import { AppConstant } from "@app/app.constant";
import { Helper } from "@app/core/common/helper";
import { GlobalService } from "@app/core/services/global.service";
import { RouterService } from "@app/core/services/router.service";
import { PolicyService } from "@app/modules/permission/policy.service";
import { AbstractFormComponent } from "@app/modules/shared/abstract-form.component";
import { ConfirmModalComponent } from "@app/modules/shared/confirm-modal/confirm-modal.component";
import { FileUploaderModel } from "@app/modules/upload/file-uploader.model";
import { ClientModel } from "@app/shared/models/client.model";
import { ItemCategoryModel } from "@app/shared/models/item-category.model";
import {
  ItemPhotoInterface,
  ItemPhotoModel,
} from "@app/shared/models/item-photo.model";
import { OneMapResultModel } from "@app/shared/models/one-map.model";
import { ClientApiService } from "@app/shared/services/client-api.service";
import { ItemApiService } from "@app/shared/services/item-api.service";
import { OneMapApiService } from "@app/shared/services/one-map-api.service";
import { UploadDirective } from "@modules/upload/upload.directive";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { debounceTime, startWith, takeUntil } from "rxjs/operators";
import { ITEM_TYPES } from "../item.constant";
import { DICTRICT } from "@shared/data/dictrict-field";
import { Subject } from "rxjs";
import { COUNTRY_LIST } from "@app/core/data/countries.data";

@Component({
  selector: "app-item-form",
  template: "",
})
export class ItemFormComponent extends AbstractFormComponent implements OnInit {
  dictricts = DICTRICT;
  dictrict = "";
  itemId: number;
  photoInit: string[] = [];
  floorPlanInit = [];
  allowedMimeType = UploadDirective.imageMineTypes;
  messageUploadFile = "Support jpg/png files under 10MB.";
  maxFiles = 5;
  isUpLoading = false;
  imgErrors: { type: "photos" | "floorPlans"; data: FileUploaderModel }[] = [];
  // categories = ['Property'];
  countries: Array<{ country: string; countryCode: string }>;
  countryControl = new FormControl();
  country = "SG";
  private destroy$ = new Subject<void>();
  types: { key: string; value: string }[] = [];
  typesFilter: { key: string; value: string }[] = [];
  singaporeAddressForm: FormGroup;
  otherAddressForm: FormGroup;
  currencyNumberMask = Helper.currencyNumberMask();
  categories: string[] = [];
  categoriesFilter: string[] = [];
  oneMapResults: OneMapResultModel[] = [];
  selectedAddress: OneMapResultModel;
  clients: ClientModel[] = [];
  selectedClient: ClientModel;
  typeFilterCtrl: FormControl = new FormControl();
  isOpenPostalBoard = true;

  constructor(
    protected itemApiService: ItemApiService,
    protected globalService: GlobalService,
    protected route: ActivatedRoute,
    protected toastrService: ToastrService,
    protected translateService: TranslateService,
    protected oneMapApiService: OneMapApiService,
    protected policyService: PolicyService,
    protected clientApiService: ClientApiService,
    @Inject(RouterService) routerService: RouterService,
    @Inject(FormBuilder) formBuilder: FormBuilder,
    @Inject(MatDialog) dialog: MatDialog,
    @Inject(Router) router: Router
  ) {
    super(routerService, formBuilder, dialog, router);
    this.singaporeAddressForm = this.initSingaporeAddressForm();
    this.otherAddressForm = this.initOtherAddressForm();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.itemApiService
      .getCategories()
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe((data: ItemCategoryModel) => {
        this.categories = data.categories;
        // Hard code 5 types for items
        this.types = data.typeLabels;
        // this.types = data.types;
        this.categoriesFilter = this.filterCategory();
        this.typesFilter = this.filterType();
        this.form.controls.category.valueChanges
          .pipe(debounceTime(AppConstant.DEFAULT_SETTINGS.DEBOUNCE_TIME))
          .subscribe((value) => {
            this.categoriesFilter = this.filterCategory(value);
          });

        // this.form.controls.type.valueChanges
        //     .pipe(debounceTime(AppConstant.DEFAULT_SETTINGS.DEBOUNCE_TIME))
        //     .subscribe(value => {
        //         this.typesFilter = this.filterType(value);
        //     });

        this.singaporeAddressForm.controls.searchPostalCodeKey.valueChanges
          .pipe(debounceTime(AppConstant.DEFAULT_SETTINGS.DEBOUNCE_TIME))
          .subscribe((value) => {
            if (value instanceof OneMapResultModel) {
              this.selectPostalCode(value);
            } else {
              this.searchPostalCode(value);
            }
          });

        this.form.controls.searchClientKey.valueChanges
          .pipe(debounceTime(AppConstant.DEFAULT_SETTINGS.DEBOUNCE_TIME))
          .subscribe((value) => {
            if (value instanceof ClientModel) {
              this.selectClient(value);
            } else {
              this.getClients(value);
            }
          });

        this.typeFilterCtrl.valueChanges
          .pipe(takeUntil(this.unsubscribeAll))
          .subscribe((value) => {
            this.typesFilter = this.filterType(value);
          });
      });
    this.getClients();
    this._listenToCountryChanges();
    this.form.controls.countryCode.valueChanges
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe((value) => {
        this.singaporeAddressForm.reset();
        this.otherAddressForm.reset();
      });
  }
  initSingaporeAddressForm(): FormGroup {
    return this.formBuilder.group({
      postalCode: ["", [Validators.required, Validators.maxLength(300)]],
      blockNumber: ["", [Validators.required]],
      streetName: ["", [Validators.required]],
      floorNumber: ["", []],
      unitNumber: ["", []],
      buildingName: ["", []],
      dictrict: [""],
      searchPostalCodeKey: ["", []],
    });
  }
  initOtherAddressForm(): FormGroup {
    return this.formBuilder.group({
      addressLine: ["", [Validators.required, Validators.maxLength(300)]],
    });
  }

  getClients(searchKey: string = "") {
    this.clientApiService
      .search({ key: searchKey, status: "active" })
      .subscribe((data) => {
        this.clients = data.results;
      });
  }

  selectClient(client: ClientModel) {
    this.selectedClient = client;
    this.form.controls.searchClientKey.patchValue("", { emitEvent: false });
  }

  removeClient() {
    this.selectedClient = null;
  }
  private _filterCountries(
    value: string
  ): { country: string; countryCode: string }[] {
    console.log("value", value);

    const filterValue = value.toLowerCase();
    const countriess: Array<{ country: string; countryCode: string }> =
      COUNTRY_LIST;
    return countriess.filter(
      (o) => o.country.toLowerCase().indexOf(filterValue) === 0
    );
  }

  private _listenToCountryChanges(): void {
    this.countryControl.valueChanges
      .pipe(startWith(""), takeUntil(this.destroy$))
      .subscribe((value) => {
        this.openPostalBoard(this.form.value.countryCode);
        this.countries = this._filterCountries(value);
        console.log(this.countries);
      });
  }
  private filterCategory(value: string = ""): string[] {
    const filterValue = value.toLowerCase();
    return this.categories.filter(
      (category) => category.toLowerCase().indexOf(filterValue) === 0
    );
  }

  private filterType(value: string = ""): { key: string; value: string }[] {
    const filterValue = value.toLowerCase();
    return this.types.filter(
      (type) => type.value.toLowerCase().indexOf(filterValue) === 0
    );
  }

  createForm(): FormGroup {
    return this.formBuilder.group({
      name: [
        "",
        [
          Validators.required,
          Validators.maxLength(AppConstant.FIELD_VALIDATION.ITEM_NAME_LENGTH),
        ],
      ],
      floorSize: ["", [Validators.maxLength(30)]],
      landSize: ["", [Validators.maxLength(30)]],
      psf: ["", [Validators.maxLength(30)]],
      tenure: ["", [Validators.maxLength(150)]],
      developer: ["", [Validators.maxLength(150)]],
      numberOfRoom: ["", [Validators.maxLength(30)]],
      price: ["", []],
      rentalPrice: ["", []],
      category: [
        "",
        [
          Validators.required,
          Validators.maxLength(AppConstant.FIELD_VALIDATION.CATEGORY_LENGTH),
        ],
      ],
      type: [
        "",
        [
          Validators.required,
          Validators.maxLength(AppConstant.FIELD_VALIDATION.CATEGORY_LENGTH),
        ],
      ],
      description: ["", []],
      photos: [[]],
      floorPlans: [[]],
      furnishings: [[], []],
      searchClientKey: ["", []],
      countryCode: ["SG"],
      numberOfBathrooms: ["", []],
      isPropertyTypeHasLand: [false, []],
      valuation: ["", []],
      valuationDate: ["", []],
      valuationCompany: ["", []],
      topYear: ["", []],
    });
  }

  changeLoadingState(state: boolean): void {
    this.isUpLoading = state;
  }

  onAddPhoto(
    data: FileUploaderModel,
    formArrayName: "photos" | "floorPlans"
  ): void {
    if (data.error.status) {
      this.imgErrors.push({ type: formArrayName, data });
    }
    // get current photos from from controll
    const photosFromFormControl = this.form.controls[formArrayName]
      .value as ItemPhotoInterface[];
    // push new photo into current photos
    photosFromFormControl.push({
      key: data.key,
      type: formArrayName === "photos" ? "photo" : "floorPlan",
    } as ItemPhotoInterface);
    // update value for form control
    this.form.controls[formArrayName].setValue(photosFromFormControl);
    this.form.markAsDirty();
  }

  onRemovePhotos(
    data: FileUploaderModel[],
    formArrayName: "photos" | "floorPlans"
  ): void {
    for (let i = 0; i < data.length; i++) {
      this.onRemovePhoto(data[i], formArrayName);
    }
  }

  onRemovePhoto(
    data: FileUploaderModel,
    formArrayName: "photos" | "floorPlans"
  ): void {
    if (data.error.status) {
      this.imgErrors = this.imgErrors.filter(
        (img) => img.data.key !== data.key
      );
    }
    // get current photos from from controll
    const photosFromFormControl = this.form.controls[formArrayName]
      .value as ItemPhotoModel[];
    // merge current photo with new photos
    photosFromFormControl.forEach((item, index) => {
      if (data.key === item.key) {
        photosFromFormControl.splice(index, 1);
        index--;
      }
    });
    // update value for form control
    this.form.controls[formArrayName].setValue(photosFromFormControl);
    this.form.markAsDirty();
  }

  comfirmFullSlot(): void {
    const msg = this.translateService.instant("upload_file.maximum_files", {
      maxFiles: this.maxFiles,
    });
    this.toastrService.error(msg, "", {
      positionClass: "toast-bottom-right",
      enableHtml: true,
    });
    // this.isUpLoading = false;
  }

  getItemPayLoad(): object {
    const formValue = this.form.getRawValue();
    const formSingaporeValue = this.singaporeAddressForm.getRawValue();
    const formOtherValue = this.otherAddressForm.getRawValue();

    const data = {
      attachments: [...formValue.photos, ...formValue.floorPlans],
      name: formValue.name,
      category: formValue.category,
      type: formValue.type,
      description: formValue.description,
      attrs: {
        countryCode: formValue.countryCode,
        addressLine: formOtherValue.addressLine,
        postalCode: formSingaporeValue.postalCode,
        dictrict: formSingaporeValue.dictrict,
        numberOfBathrooms: formValue.numberOfBathrooms,
        isPropertyTypeHasLand: formValue.isPropertyTypeHasLand,
        topYear: formValue.topYear,
        valuation: formValue.valuation,
        valuationDate: moment.isMoment(formValue.valuationDate)
          ? formValue.valuationDate.toISOString()
          : formValue.valuationDate,
        valuationCompany: formValue.valuationCompany,
        unitNumber: formSingaporeValue.unitNumber,
        blockNumber: formSingaporeValue.blockNumber,
        streetName: formSingaporeValue.streetName,
        buildingName: formSingaporeValue.buildingName,
        floorNumber: formSingaporeValue.floorNumber,
        floorSize: formValue.floorSize,
        landSize: formValue.landSize,
        psf: formValue.psf,
        tenure: formValue.tenure,
        developer: formValue.developer,
        numberOfRoom: formValue.numberOfRoom,
        furnishings: formValue.furnishings,
        price: Helper.removeCommaFromText(formValue.price),
        rentalPrice: Helper.removeCommaFromText(formValue.rentalPrice),
        address: this.selectedAddress,
      },
    };

    if (this.selectedClient) {
      data.attrs["linkedCustomer"] = {
        id: this.selectedClient.id,
        customerName: this.selectedClient.name,
        contactName: this.selectedClient.mainContact?.name,
        contactEmail: this.selectedClient.mainContact?.email,
      };
    }

    if (this.itemId) {
      data["id"] = +this.itemId;
    }
    return data;
  }

  cancel(): void {
    this.navigatePreviousUrl();
  }

  navigatePreviousUrl(): void {
    const previousUrl = this.getPreviousUrl();
    this.router.navigateByUrl(previousUrl || "item");
  }

  submit(): void {
    this.singaporeAddressForm.markAllAsTouched();
    this.otherAddressForm.markAllAsTouched();
    if (
      this.form?.controls["countryCode"]?.value === "SG" &&
      this.singaporeAddressForm.invalid
    ) {
      this.scrollToError();
      return;
    }
    if (
      this.form?.controls["countryCode"]?.value !== "SG" &&
      this.otherAddressForm.invalid
    ) {
      this.scrollToError();
      return;
    }
    if (this.form.invalid) {
      this.scrollToError();
      return;
    }
    if (this.imgErrors.length > 0) {
      this.confirmRemovePhotoError();
      return;
    }
    const data = this.getItemPayLoad();
    this.sendRequest(data);
  }

  confirmRemovePhotoError(): void {
    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      width: "410px",
      maxHeight: "900px",
      autoFocus: false,
      disableClose: true,
      data: {
        title: "confirm.title.photo_upload_error",
        message: "confirm.message.photo_upload_error",
        confirmLabel: "confirm.label.continue",
      },
    });

    dialogRef.afterClosed().subscribe((isConfirm) => {
      if (isConfirm) {
        const imgErrors = this.imgErrors;
        for (let i = 0; i < imgErrors.length; i++) {
          this.onRemovePhoto(imgErrors[i].data, imgErrors[i].type);
        }
        setTimeout(() => this.submit(), 0);
      }
    });
  }

  sendRequest(data: object): void {}

  canDeactivate(): boolean {
    return !this.ngForm.dirty || (this.ngForm.submitted && this.form.valid);
  }

  add(event): void {
    const input = event.input;
    const furnishing = event.value;

    if ((furnishing || "").trim()) {
      const furnishings = this.form.controls.furnishings.value || [];
      furnishings.push(furnishing.trim());
      this.form.controls.furnishings.patchValue(furnishings);
    }
    if (input) {
      input.value = "";
    }
  }

  remove(furnishing: string): void {
    const furnishings = this.form.controls.furnishings.value;
    const index = furnishings.indexOf(furnishing.trim());

    if (index >= 0) {
      furnishings.splice(index, 1);
    }
    this.form.controls.furnishings.patchValue(furnishings);
  }

  searchPostalCode(value: string) {
    if (value) {
      this.oneMapApiService
        .search({
          searchVal: value,
          returnGeom: "N",
          getAddrDetails: "Y",
          // pageNum: '1'
        })
        .subscribe(
          (data: {
            found: number;
            pageNum: number;
            totalNumPages: number;
            results: OneMapResultModel[];
          }) => {
            this.oneMapResults = data.results.map((v) =>
              OneMapResultModel.fromJson(v)
            );
          }
        );
    }
  }

  getDictrict(postalCode: string) {
    const firstTwoDigits = postalCode.slice(0, 2);
    Object.keys(this.dictricts).forEach((key) => {
      // lay data
      if (this.dictricts[key].value.includes(firstTwoDigits)) {
        this.dictrict = this.dictricts[key].name;
      }
    });
    return this.dictrict;
  }

  selectPostalCode(data: OneMapResultModel) {
    this.selectedAddress = this.removeDataNullFromOneMapAddress(data);

    if (this.selectedAddress.POSTAL) {
      this.getDictrict(this.selectedAddress.POSTAL);
    }

    this.singaporeAddressForm.patchValue(
      {
        searchPostalCodeKey: "",
        postalCode: this.selectedAddress.POSTAL,
        blockNumber: this.selectedAddress.BLK_NO,
        streetName: this.selectedAddress.ROAD_NAME,
        buildingName: this.selectedAddress.BUILDING,
        dictrict: this.dictrict,
        countryCode: this.country,
        unitNumber: null,
        floorNumber: null,
      },
      { emitEvent: false }
    );
  }

  displayWith(): string {
    return "";
  }

  removeDataNullFromOneMapAddress(data: OneMapResultModel): OneMapResultModel {
    Object.keys(data).forEach((key) => {
      if (data[key] === "NIL") {
        data[key] = "";
      }
    });

    return data;
  }

  openPostalBoard(value: string) {
    if (value && value.toLowerCase() !== "sg") {
      this.isOpenPostalBoard = false;
    } else {
      this.isOpenPostalBoard = true;
    }
  }
}
