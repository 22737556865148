<div class="app-rating-bar" [class.rating-bar-readonly]="readOnly" [class.rating-bar-disabled]="disabled">

    <div class="list-rating-units" (mouseleave)="reset()" *ngIf="controls && controls.length > 0">
        <ng-container *ngFor="let unit of controls">
            <mat-icon [svgIcon]="unit.selected || unit.active ? 'rated-star' : 'star'" class="rating-unit"
                [class.rating-unit-selected]="unit.selected" [class.rating-unit-active]="unit.active"
                (click)="unit.click($event)" (mouseenter)="unit.enter()">
            </mat-icon>
        </ng-container>
    </div>
</div>
