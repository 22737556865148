import { UppercaseDirective } from './uppercase.directive';
import { NgModule } from '@angular/core';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { LoadImageDirective } from './load-image.directive';
import { FileDropDirective } from './file-drop.directive';
import { StatusLabelDirective } from './status-label.directive';
import { ClickOutDirective } from './click-out.directive';
import { ContentEditableDirective } from './content-editable.directive';
import { OverlayModule } from '@angular/cdk/overlay';
import { NumberOnlyDirective } from './number-only.directive';
import { QuantityNumberDirective } from './quantity.directive';
import { DebounceClickDirective } from './debounce-click.directive';
import { ScrollEndDirective } from './scroll-end.directive';
import { HoveredDirective } from './hovered.directive';
import { TrimValueAccessorDirective } from './trim.directive';
import { AutocompletePositionDirective } from './autocomplete-position.directive';
import { ScaleByWindowDirective } from './scale-by-window.directive';


@NgModule({
    imports: [
        InfiniteScrollModule,
        OverlayModule
    ],
    declarations: [
        LoadImageDirective,
        FileDropDirective,
        StatusLabelDirective,
        ClickOutDirective,
        ContentEditableDirective,
        NumberOnlyDirective,
        QuantityNumberDirective,
        DebounceClickDirective,
        ScrollEndDirective,
        HoveredDirective,
        UppercaseDirective,
        TrimValueAccessorDirective,
        AutocompletePositionDirective,
        ScaleByWindowDirective
    ],
    exports: [
        LoadImageDirective,
        FileDropDirective,
        ClickOutDirective,
        InfiniteScrollModule,
        ContentEditableDirective,
        OverlayModule,
        NumberOnlyDirective,
        QuantityNumberDirective,
        DebounceClickDirective,
        ScrollEndDirective,
        HoveredDirective,
        UppercaseDirective,
        TrimValueAccessorDirective,
        AutocompletePositionDirective,
        ScaleByWindowDirective
    ],
    providers: []
})
export class DirectivesModule {
}
