import * as FileSaver from 'file-saver';
import { COUNTRY_LIST } from '../data/countries.data';

export const SCREENWIDTH = {
    MOBILE: 768,
    TABLET: 992,
};

export function getScreenWidth() {
    return (
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth
    );
}

export const isMobileScreen = () => getScreenWidth() < SCREENWIDTH.MOBILE;
export const isTabletScreen = () =>
    getScreenWidth() < SCREENWIDTH.TABLET &&
    getScreenWidth() >= SCREENWIDTH.MOBILE;
export const isDesktopScreen = () => getScreenWidth() > SCREENWIDTH.TABLET;

export function getScreenHeigth() {
    return (
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight
    );
}

export function decodeJWT(token) {
    const base64Url = token.split('.')[1];
    if (!base64Url) {
        return false;
    }
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    try {
        const jsonPayload = decodeURIComponent(
            atob(base64)
                .split('')
                .map((c) => {
                    return (
                        '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
                    );
                })
                .join('')
        );
        return JSON.parse(jsonPayload);
    } catch (error) {
        return false;
    }
}

// from
// https://stackoverflow.com/questions/9847580/how-to-detect-safari-chrome-ie-firefox-and-opera-browser
// https://stackoverflow.com/questions/34274487/property-does-not-exists-on-type
export const BrowserDetector = {
    // Firefox 1.0+
    isFirefox: () => {
        return typeof (window as any).InstallTrigger !== 'undefined';
    },
    // Internet Explorer 6-11
    isIE: () => {
        return (
            navigator.userAgent.indexOf('MSIE') !== -1 ||
            !!(document as any).documentMode
        );
    },
    // Edge 20+
    isEdge: () => {
        return !BrowserDetector.isIE() && !!(window as any).StyleMedia;
    },
    // Chrome 1+
    isChrome: () => {
        // return !!(window as any).chrome && !!(window as any).chrome.webstore;
        return !!(window as any).chrome; // Cam fixed
    },
    // At least Safari 3+: "[object HTMLElementConstructor]"
    isSafari: () => {
        return (
            Object.prototype.toString
                .call((window as any).HTMLElement)
                .indexOf('Constructor') > 0 ||
            navigator.userAgent.toLowerCase().indexOf('safari') !== -1
        );
    },
};

export const DeviceDetector = {
    isIOSUser: () => /iPhone/.test(navigator.userAgent || navigator.vendor),
    isAndroidUser: () =>
        /Android/.test(navigator.userAgent || navigator.vendor),
};

export function transformAddressObjectToString(objectAddress: {
    postalCode?: string;
    unitNumber?: string;
    blockNumber?: string;
    streetName?: string;
    buildingName?: string;
    country?: string;
}): string {
    const str =
        (objectAddress?.blockNumber ? objectAddress?.blockNumber : '') +
        ' ' +
        (objectAddress?.streetName ? objectAddress?.streetName : '') +
        ' ' +
        (objectAddress?.unitNumber ? objectAddress?.unitNumber : '') +
        ' ' +
        (objectAddress?.buildingName ? objectAddress?.buildingName : '') +
        ' ' +
        (objectAddress?.country
            ? objectAddress?.country.toUpperCase()
            : 'SINGAPORE') +
        ' ' +
        (objectAddress?.postalCode ? objectAddress?.postalCode : '');
    return str.replace(/[^\S\r\n]{2,}/, ' ').trim();
}

export function transformPropertyAddrObjectToString(objectAddress: {
    postalCode?: string;
    unitNumber?: string;
    blockNumber?: string;
    floorNumber?: string;
    streetName?: string;
    buildingName?: string;
    countryCode?:string;
    addressLine?:string;
}) {
    const countryCode = (objectAddress.countryCode || 'sg').toUpperCase();
    // console.log('countryCode --> ',countryCode)
    const countryName = COUNTRY_LIST.find(item => item.countryCode === countryCode)?.country 
    if(countryCode === 'SG'){
        // const { floorNumber, unitNumber } = objectAddress;
        const buildingAddrInfo = formatBuildingAddr(
            objectAddress?.floorNumber,
            objectAddress?.unitNumber
        );

        const str =
            (objectAddress?.blockNumber ? objectAddress?.blockNumber : '') +
            ' ' +
            (objectAddress?.streetName ? objectAddress?.streetName : '') +
            ' ' +
            buildingAddrInfo +
            ' ' +
            (objectAddress?.buildingName ? objectAddress?.buildingName : '') +
            ' SINGAPORE ' +
            (objectAddress?.postalCode ? objectAddress?.postalCode : '');
        return str.replace(/[^\S\r\n]{2,}/, ' ').trim();
    }
    const address = [objectAddress.addressLine?.trim() || '',countryName].filter(item => !!item).join(' ');
    return address
}

export function formatBuildingAddr(floorNumber, unitNumber): string {
    if (!floorNumber && !unitNumber) {
        return '';
    }

    /**
     * Full infomation
     * #{floorNumber}-{unitNumber}
     */
    if (floorNumber && unitNumber) {
        return (
            (floorNumber.startsWith('#') ? floorNumber : `#${floorNumber}`) +
            (unitNumber.startsWith('-') ? unitNumber : `-${unitNumber}`)
        );
    }

    /**
     * Only floorNumber
     * #{floorNumber}
     */
    if (floorNumber) {
        return floorNumber.startsWith('#') ? floorNumber : `#${floorNumber}`;
    }

    /**
     * Only unitNumber
     */
    if (unitNumber) {
        return unitNumber;
    }
}

export interface CSVHeader {
    key: string;
    label: string;
}

export interface CSVRowData {
    key: string;
    data: string | number | (() => any);
}

export function exportCSV(
    fileName: string,
    header: CSVHeader[] = [],
    rows: any[]
) {
    const BOM = '\uFEFF';
    // let csvContent = "data:text/csv;charset=utf-8,";
    let csvData = BOM;

    /* Prepare Header */
    csvData += prepareCSVHeader(header);

    /* Prepare Row Data */
    csvData = prepareCSVRowData(header, rows, csvData);

    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8' });

    FileSaver.saveAs(blob, `${fileName}.csv`);
}

function prepareCSVRowData(
    headers: CSVHeader[] = [],
    rows: Array<CSVRowData[]> = [],
    csvData = ''
) {
    let content = csvData;

    for (const rowItem of rows) {
        const rowArr = [];

        for (const col of rowItem) {
            const index = headers.findIndex((item) => item.key === col.key);

            if (index !== -1) {
                if (
                    typeof col.data === 'string' ||
                    typeof col.data === 'number'
                ) {
                    rowArr[index] = `"${col.data}"`;
                }

                if (typeof col.data === 'function') {
                    rowArr[index] = `"${col.data()}"`;
                }
            }
        }

        content += rowArr.join(',') + '\r\n';
    }

    return content;
}

function prepareCSVHeader(headers: CSVHeader[] = []) {
    const headerRow = [];

    headers.forEach((header) => {
        headerRow.push(`\"${header.label}\"`);
    });

    return headerRow.join(',') + '\r\n';
}
