<div class="app-request-subscription">
    <div fxLayout="row" fxLayoutAlign="end center">
        <button type="button" mat-icon-button (click)="close()" *ngIf="!isHideCloseButton">
            <mat-icon>close</mat-icon>
        </button>
        <button type="button" mat-icon-button routerLink="/auth/logout" *ngIf="!isHideLogoutButton" matTooltip="Logout">
            <mat-icon>logout</mat-icon>
        </button>
    </div>
    <div class="app-request-subscription-content" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="24px">
        <div>
            <img src="assets/images/red-logo-black.svg">
        </div>
        <div style="padding-top: 16px;">
            <label class="switch-mode-btn">
                <input type="checkbox" [formControl]="inputCtrl" />
                <div class="slider">
                    <div class="slider__mode">
                        <span>Pay Monthly</span>
                    </div>
                    <div class="slider__mode">
                        <span>Pay Annually</span>
                    </div>
                    <div class="slider__switcher">
                        <span>{{interval === 'month' ? 'Pay Monthly' : 'Pay Annually' }}</span>
                    </div>
                    <div class="save">Save up to {{saveUpPercentage}}%</div>
                </div>
            </label>
        </div>
        <div class="block-wrapper">
            <div class="block" *ngFor="let plan of plans">
                <img appLoadImage [key]="plan.attrs?.photo" width="172px" height="172px">

                <div class="block-text-1">{{plan.name}}</div>
                <div class="block-currency">{{ (interval === 'month' ? plan.prices.month : plan.pricePerMonthOfYear) |
                    currency: currency}}</div>
                <div class="block-text-2">per user, per month</div>
                <div class="btn-submit" (click)="upgrade(plan, interval)">
                    Upgrade
                </div>

                <div class="block-content" *ngFor="let item of plan.attrs.intro" fxLayout="row" fxLayoutGap="8px">
                    <mat-icon color="accent">check_circle</mat-icon>
                    <div class="block-content-text">{{item}}</div>
                </div>

            </div>
        </div>
    </div>
</div>
