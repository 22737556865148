import { SelectionModel } from '@core/cdk/collections';
import { Component, OnChanges, OnInit, SimpleChanges, Output, Input, EventEmitter } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AppConstant } from '@app/app.constant';
import { GlobalService } from '@app/core/services/global.service';
import { RedrValidator } from '@app/core/validators/redr.validator';
import { UploadDirective } from '@app/modules/upload/upload.directive';
import { MESSAGE_TYPE } from '@app/shared/models/message.model';
import { ClientRoleModel } from '@app/shared/models/metadata.model';
import { OneMapResultModel } from '@app/shared/models/one-map.model';
import { MetadataApiService } from '@app/shared/services/metadata-api.service';
import { OneMapApiService } from '@app/shared/services/one-map-api.service';
import { TranslateService } from '@ngx-translate/core';
import { debounceTime } from 'rxjs/operators';
import { ClientModel } from '@app/shared/models/client.model';
import { PhoneNumber } from '@app/modules/shared/phone-number/phone-number.component';
import { CountryModel } from '@app/core/models/country.model';
import { Helper } from '@app/core/common/helper';
import { UuidService } from '@app/core/services/uuid.service';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { IQuerySearch } from '@app/shared/models/query-search.model';
import { VendorApiService } from '@app/shared/services/vendor-api.service';
import { VendorModel } from '@app/shared/models/vendor.model';
import { of } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { VendorCreateOrUpdateDialogComponent } from '@app/modules/vendor/vendor-create-or-update-dialog/vendor-create-or-update-dialog.component';

@Component({
    selector: 'app-client-company-form',
    templateUrl: './client-company-form.component.html',
    styleUrls: ['./client-company-form.component.scss']
})
export class ClientCompanyFormComponent implements OnInit, OnChanges {
    @Output() requestSubmit = new EventEmitter<{ payload: any, form: FormGroup}>();
    @Input() client: ClientModel;
    form: FormGroup;
    clientRoles: ClientRoleModel[] = [];
    countries: CountryModel[] = [];
    oneMapResults: OneMapResultModel[] = [];
    roleSelected = new SelectionModel<string>(true, []);
    fileUploader;
    allowedMimeType = UploadDirective.imageMineTypes;
    isCompanyLead = false;
    vendorCtrl = new FormControl()
    constructor(
        private globalService: GlobalService,
        private translateService: TranslateService,
        private metadataApiService: MetadataApiService,
        private formBuilder: FormBuilder,
        private oneMapApiService: OneMapApiService,
        private uuidService:UuidService,
        private _dialog:MatDialog,
        private vendorApiService:VendorApiService
    ) {
        this._initForm();
    }

    ngOnInit(): void {
        this.metadataApiService.getCountries().subscribe(countries => {
            this.countries = countries;
        })
        
        this.metadataApiService.getClientRoles().subscribe(data => {
            this.clientRoles = data;
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.client) {
            this._patchValue();
        }
    }

    numberOnly(e) {
        return Helper.numberOnly(e);
    }
    onCompanyLeadChange(event:MatCheckboxChange):void{
        this.isCompanyLead = event.checked;

    }
    onVendorSelected(item: VendorModel | 'createNew'): void {
        console.log('onVendorSelected',item)
        if (item === 'createNew') {
            const dialogRef = this._dialog.open(VendorCreateOrUpdateDialogComponent);
            dialogRef.afterClosed().subscribe((res?:VendorModel) => {
                if(!res){
                    return
                }
                this.vendorCtrl.setValue(res)
            })
        }else{
            this.vendorCtrl.setValue(item)
        }
    }
    private _patchValue() {
        this.fileUploader = {
            key: this.client.logo
        };
        this.form.patchValue({
            name: this.client.name,
            postalCode: this.client?.fields?.postalCode,
            blockNumber: this.client?.fields?.blockNumber,
            uenNumber: this.client?.fields?.uenNumber,
            unitNumber: this.client?.fields?.unitNumber,
            streetName: this.client?.fields?.streetName,
            buildingName: this.client?.fields?.buildingName,
            email: this.client?.mainContact?.email,
            contactName: this.client?.mainContact?.name,
            nricNumber: this.client?.contacts[0]?.nricNumber,
            dateOfBirth: this.client?.contacts[0]?.dateOfBirth,
            gender: this.client?.contacts[0]?.fields?.gender,
            citizenship: this.client?.contacts[0]?.fields?.citizenship,
            salutation: this.client?.contacts[0]?.fields?.salutation,
            dictrict: this.client?.contacts[0]?.fields?.dictrict,
            landline: this.client?.contacts[0]?.fields?.landline,
            fax: this.client?.contacts[0]?.fields?.fax,
            residencyStatus: this.client?.contacts[0]?.fields?.residencyStatus,
            phone: new PhoneNumber({
                phoneNumber: this.client?.mainContact?.phone,
                countryCode: this.client?.mainContact?.countryPhoneCode
            }),
            website: this.client.website,
            designation: this.client?.mainContact?.fields?.designation,
            nationality: !!this.client?.fields?.nationality ? this.client?.fields?.nationality : 'SINGAPORE'
        }, { emitEvent: false });

        this.roleSelected.select(...this.client.roles);
    }

    private _initForm() {
        this.form = this.formBuilder.group({
            name: ['', [Validators.required, Validators.maxLength(AppConstant.FIELD_VALIDATION.NAME_LENGTH)]],
            contactName: ['', [Validators.required, Validators.maxLength(AppConstant.FIELD_VALIDATION.NAME_LENGTH)]],
            email: ['', [RedrValidator.email, Validators.required, Validators.maxLength(AppConstant.FIELD_VALIDATION.EMAIL_LENGTH)]],
            phone: ['', [Validators.required, Validators.maxLength(AppConstant.FIELD_VALIDATION.PHONE_LENGTH)]],
            website: ['', [Validators.maxLength(AppConstant.FIELD_VALIDATION.WEBSITE_LENGTH)]],
            postalCode: ['', [Validators.required]],
            blockNumber: ['', [Validators.required]],
            streetName: ['', [Validators.required]],
            unitNumber: ['', []],
            dictrict: ['', []],
            buildingName: ['', []],
            uenNumber: ['', [Validators.required]],
            designation: ['', []],
            nationality: ['', [Validators.required]],
            nricNumber: ['', []],
            dateOfBirth: ['', []],
            gender: [],
            citizenship: ['', []],
            landline: ['', []],
            fax: ['',[]],
            residencyStatus: [],
            salutation: []
        });

        this.form.controls.postalCode.valueChanges
            .pipe(debounceTime(AppConstant.DEFAULT_SETTINGS.DEBOUNCE_TIME))
            .subscribe(value => {
                if (value instanceof OneMapResultModel) {
                    this._selectPostalCode(value);
                } else {
                    this._searchPostalCode(value);
                }
            });
    }

    private _searchPostalCode(value: string) {
        if (value) {
            this.oneMapApiService.search({
                searchVal: value,
                returnGeom: 'N',
                getAddrDetails: 'Y',
                // pageNum: '1'
            }).subscribe((data: {
                found: number;
                pageNum: number;
                totalNumPages: number;
                results: OneMapResultModel[]
            }) => {
                this.oneMapResults = data.results.filter(v => v.POSTAL !== 'NIL').map(v => OneMapResultModel.fromJson(v));
            });
        }
    }

    private _removeDataNullFromOneMapAddress(data: OneMapResultModel): OneMapResultModel {
        Object.keys(data).forEach(key => {
            if (data[key] === 'NIL') {
                data[key] = '';
            }
        });

        return data;
    }

    private _selectPostalCode(data: OneMapResultModel) {
        const temp = this._removeDataNullFromOneMapAddress(data);
        this.form.patchValue({
            blockNumber: temp.BLK_NO,
            streetName: temp.ROAD_NAME,
            buildingName: temp.BUILDING,
            unitNumber: null
        });
    }

    onFinishedFile(file) {
        if (file.completed) {
            this.fileUploader = file;
        } else {
            this.globalService.message.next({
                type: MESSAGE_TYPE.error,
                message: this.translateService.instant(file.error.message)
            });
        }
    }
    searchWithFn(filters: IQuerySearch) {
        return this.vendorApiService.search(filters)
    }
    submit() {
        if (this.form.invalid) {
            return;
        }

        const rawData = this.form.getRawValue();
        let postalCode = '';
        if (rawData.postalCode instanceof OneMapResultModel) {
            postalCode = rawData.postalCode.POSTAL;
        } else {
            postalCode = rawData.postalCode;
        }
        const profile = rawData.phone as PhoneNumber;
        const vendor = this.vendorCtrl.value;
        const data = {
            type: AppConstant.CUSTOMER_TYPE.COMPANY,
            name: rawData.name,
            roles: this.roleSelected.selected,
            website: rawData.website || '',
            fields: {
                postalCode,
                blockNumber: rawData.blockNumber,
                unitNumber: rawData.unitNumber,
                uenNumber: rawData.uenNumber,
                streetName: rawData.streetName,
                buildingName: rawData.buildingName,
                nationality: rawData.nationality,
            },
            contacts: [{
                name: rawData.contactName,
                email: rawData.email,
                phone: profile.phoneNumber,
                countryPhoneCode: profile.countryCode,
                nricNumber: rawData.nricNumber,
                dateOfBirth: rawData.dateOfBirth,
                passportNumber: rawData.passportNumber,
                fields: {
                    designation: rawData.designation,
                    gender: rawData.gender,
                    citizenship: rawData.citizenship,
                    landline: rawData.landline,
                    fax: rawData.fax,
                    residencyStatus: rawData.residencyStatus,
                    salutation: rawData.salutation,
                    dictrict: rawData.dictrict
                }
            }],
            vendorId:(vendor && vendor.id) ? vendor.id : null
        };

        if (this.fileUploader) {
            data['logo'] = this.fileUploader.key;
            data.contacts[0]['avatar'] = this.fileUploader.key;
        }

        this.requestSubmit.emit({
            payload: data,
            form: this.form
        });
    }

    displayWith(value: OneMapResultModel): string {
        if (value instanceof OneMapResultModel) {
            return value.POSTAL;
        } else {
            return value;
        }
    }
    customizeFileNameFn(file:File):string{
        return `${this.uuidService.generate()}`
    }
}
