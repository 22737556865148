import { Component, Input } from "@angular/core";
import { VendorModel } from "../../../shared/models/vendor.model";

@Component({
    selector: 'app-vendor-create-or-update-dialog',
    templateUrl: './vendor-create-or-update-dialog.component.html',
    styleUrls: ['./vendor-create-or-update-dialog.component.scss'],
})
export class VendorCreateOrUpdateDialogComponent{
    @Input() item: VendorModel;
}