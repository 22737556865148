<div class="app-walkthrough-modal">
    <div class="title">{{ modules[index].title | translate | titlecase}}</div>
    <div class="img-shadow">
        <img [src]="modules[index].url" class="img-shadow">
    </div>
    <div class="dot-wrapper" fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="center center">
        <div class="dot" *ngFor="let data of modules; let i = index" [ngClass]="{'dot-active': i === index}"></div>
    </div>
    <div class="description" fxLayout="row" fxLayoutAlign="center center">
        <div class="description-content">{{ modules[index].description | translate}}</div>
    </div>
    <div class="line"></div>
    <div class="walkthrough-button" fxLayout="row" fxLayoutAlign="space-between center">
        <button type="button" mat-button (click)="close()">{{ 'common.skip' | translate}}</button>

        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="24px">
            <button type="button" mat-flat-button class="back-btn" (click)="back()" *ngIf="index > 0">
                <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
                    <mat-icon>keyboard_arrow_left</mat-icon>
                    <span>{{ 'common.back' | translate}}</span>
                </div>
            </button>
            <button type="button" mat-flat-button color="primary" (click)="next()" *ngIf="index !== modules.length - 1">
                <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
                    <span>{{ 'common.next' | translate}}</span>
                    <mat-icon>keyboard_arrow_right</mat-icon>
                </div>
            </button>
        </div>
    </div>
</div>
