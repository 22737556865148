import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-walkthrough-modal',
    templateUrl: './walkthrough-modal.component.html',
    styleUrls: ['./walkthrough-modal.component.scss']
})
export class WalkthroughModalComponent implements OnInit {
    modules = [{
        title: 'common.templates',
        description: 'walkthrough.template_des',
        url: '../../../../assets/images/walkthrough-template.png',
    }, {
        title: 'common.clients',
        description: 'walkthrough.client_des',
        url: '../../../../assets/images/walkthrough-client.png',
    }, {
        title: 'common.properties',
        description: 'walkthrough.properties_des',
        url: '../../../../assets/images/walkthrough-properties.png',
    },
    {
        title: 'common.my_cases',
        description: 'walkthrough.case_des',
        url: '../../../../assets/images/walkthrough-case.png',
    }];
    index = 0;

    constructor(
        private dialogRef: MatDialogRef<WalkthroughModalComponent>
    ) {

    }

    ngOnInit() { }

    next() {
        this.index = this.index === this.modules.length - 1 ? this.index : this.index + 1;
    }

    back() {
        this.index = this.index <= 0 ? 0 : this.index - 1;
    }

    close(isStartTour: boolean = false) {
        this.dialogRef.close(isStartTour);
    }

    startTour() {
        this.close(true);
    }
}
