import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { AppConstant } from '@app/app.constant';
import { GRECAPTCHA_SITE_KEY, GrecaptchaService } from '@app/shared/grecaptcha';
import { Observable, throwError, timer } from 'rxjs';
import { catchError, mergeMap, retryWhen } from 'rxjs/operators';
import { GlobalService } from '../services/global.service';
import { MESSAGE_TYPE } from '@app/shared/models/message.model';

const genericRetryStrategy =
    ({
        maxRetryAttempts = 2,
        scalingDuration = 1000,
        excludedStatusCodes = [],
        onlyErrorCodes: errorCodes = [],
    }: {
        maxRetryAttempts?: number;
        scalingDuration?: number;
        excludedStatusCodes?: number[];
        onlyErrorCodes?: string[];
    } = {}) =>
    (attempts: Observable<any>) => {
        return attempts.pipe(
            mergeMap((error, i) => {
                const retryAttempt = i + 1;
                // if maximum number of retries have been met
                // or response is a status(error) code we don't wish to retry, throw error
                if (
                    retryAttempt > maxRetryAttempts ||
                    excludedStatusCodes.find((e) => e === error.statusCode) ||
                    (errorCodes.filter((e) => !!e).length &&
                        !errorCodes.includes(error.errorCode))
                ) {
                    return throwError(error);
                }
                console.log(
                    `Attempt ${retryAttempt}: retrying in ${
                        retryAttempt * scalingDuration
                    }ms`
                );
                // retry after 1s, 2s, etc...
                return timer(retryAttempt * scalingDuration);
            })
        );
    };

@Injectable()
export class RecaptchaInterceptor implements HttpInterceptor {
    constructor(
        @Optional() private recaptchaService: GrecaptchaService,
        @Optional() @Inject(GRECAPTCHA_SITE_KEY) private siteKey: string
    ) {}

    intercept(
        request: HttpRequest<unknown>,
        next: HttpHandler
    ): Observable<HttpEvent<unknown>> {
        // Just for some tracking from backend
        if (
            request.url.startsWith(AppConstant.API_HOST) &&
            request.method === 'POST' &&
            this.recaptchaService &&
            this.siteKey
        ) {
            // input: https://api.example.com/api/v1.2.3#4/abc-def
            const action = request.url
                .replace(AppConstant.API_HOST + AppConstant.API_URL_PREFIX, '')
                .replace(/\//g, '__') // replace "/" to "__"
                .replace(
                    /[/!\@\#\$\%\^\&\*\(\)\-\+\=\{\[\]\}\\\|\:\;\'\"\<\>\,\.\/\?\`\~]/g,
                    '_'
                ); // replace special chars to "_"
            // output: "v1_2_3_4__abc_def"

            return this.recaptchaService.recaptchaToken(action).pipe(
                mergeMap((token) => {
                    request = request.clone({
                        headers: request.headers.set('x-captcha-token', token),
                    });

                    return next.handle(request);
                }),
                retryWhen(
                    genericRetryStrategy({
                        excludedStatusCodes: [500, 403],
                        onlyErrorCodes: ['CAPT001'],
                    })
                ),
                catchError((error) => {
                    console.log('error', error);
                    return throwError(error);
                })
            );
        }

        return next.handle(request);
    }
}
