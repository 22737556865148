import { NavigationItem } from "./classic-layout/navigation";
export const navigationSettings: NavigationItem[] = [
  {
    id: "back_to_home",
    icon: "keyboard_return",
    title: "Home",
    type: "basic",
    link: `/signed-in-redirect`,
    meta: {
      permission: {
        disabled: true,
      },
    },
  },
  {
    id: "profile",
    icon: "manage_accounts",
    title: "common.profile",
    type: "basic",
    link: `/setting/profile`,
    meta: {
      permission: {
        slug: "setting::view",
      },
    },
  },
  {
    id: "access_management",
    title: "common.access_management",
    // tooltip: 'Report',
    type: "collapsable",
    icon: "admin_panel_settings",
    children: [
      
      {
        id: "access_management.group_permission",
        title: "common.groups",
        type: "basic",
        link: `/setting/group-and-permission`,
        meta: {
          permission: {
            slug: "setting::view",
          },
        },
      },
      {
        id: "access_management.activity_log",
        title: "common.activity_log",
        type: "basic",
        link: `/setting/activity-log`,
        meta: {
          permission: {
            slug: "setting::view",
          },
        },
      },
      {
        id: "access_management.team_members",
        title: "common.team_members",
        type: "basic",
        link: `/setting/team-member`,
        meta: {
          permission: {
            slug: "setting::view",
          },
        },
      },
      {
        id: "access_management.access_control",
        title: "common.permissions",
        type: "basic",
        link: `/setting/access-control`,
        meta: {
          permission: {
            slug: "access-control::accessUrl",
          },
        },
      },
    ], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
  },
  {
    id: "org_bu",
    title: "common.org_bu",
    // tooltip: 'Report',
    type: "collapsable",
    icon: "building",
    isSvgIcon:true,
    children: [
      {
        id: "org_bu.org_bu",
        title: "common.general_info",
        type: "basic",
        link: `/setting/organization-and-business`,
        meta: {
          permission: {
            slug: "setting::orgInfo",
          },
        },
      },
      {
        id: "org_bu.plan_and_billing",
        title: "common.plan_and_billing",
        type: "basic",
        link: `/setting/billing-info`,
        meta: {
          permission: {
            slug: "setting::viewSubscription",
          },
        },
      },
    ]
  },
  {
    id: "configuration",
    title: "common.configuration",
    // tooltip: 'Report',
    type: "collapsable",
    icon: "settings",
    children: [
      {
        id: "configuration.accounting_settings",
        title: "common.accounting_settings",
        type: "basic",
        link: `/setting/account-setting`,
        meta: {
          permission: {
            slug: "setting::invoice",
          },
        },
      },
      {
        id: "configuration.case_management",
        title: "common.case_management",
        type: "basic",
        link: `/setting/case-managements`,
        meta: {
          permission: {
            slug: "setting::caseSubmission",
          },
        },
      },
      {
        id: "configuration.document_setting",
        title: "common.document_setting",
        type: "basic",
        link: `/setting/document`,
        meta: {
          permission: {
            slug: "setting::document",
          },
        },
      },
     
      {
        id: "configuration.zoom_account",
        title: "user_setting.zoom-account",
        type: "basic",
        link: `/setting/zoom-account`,
        meta: {
          permission: {
            slug: "setting::zoom",
          },
        },
      },
      {
        id: "configuration.kyc_setup",
        title: {
          text:"KYC/AML Set Up",
          disableTransform:true
        },
        type: "basic",
        link: `/setting/kyc-setup`,
        meta: {
          permission: {
            slug: "setting::kyc",
          },
        },
      },
      {
        id: "configuration.xero_setup",
        title: "common.xero_setup",
        type: "basic",
        link: `/setting/xero-setup`,
        meta: {
          permission: {
            slug: "setting::xero",
          },
        },
      },
      {
        id: "configuration.webhook",
        title: "common.web_hook",
        type: "basic",
        link: `/setting/web-hook`,
        meta: {
          permission: {
            slug: "setting::webhook",
          },
        },
      },
    ],
  },
];
export const defaultNavigation: NavigationItem[] = [
  {
    id: "dashboard",
    title: "Dashboard",
    // tooltip: 'Dashboard',
    type: "basic",
    icon: "dashboard",
    link: "/dashboard",
    meta: {
      permission: {
        slug: "dashboard::accessUrl",
      },
    },
  },
  {
    id: "case_submission_management",
    title: "common.case_submission",
    // tooltip: 'Report',
    type: "collapsable",
    icon: "my-case",
    isSvgIcon: true,
    children: [
      {
        id: "case_submission_management.cases",
        title: "common.cases",
        type: "basic",
        link: `/case`,
        meta: {
          permission: {
            slug: "personal-case::accessUrl",
          },
        },
      },
      {
        id: "case_submission_management.my_case",
        title: "common.my_cases",
        type: "basic",
        link: `/case-submission`,
        meta: {
          permission: {
            slug: "case-submission::accessUrlMyCase",
          },
        },
      },
      {
        id: "case_submission_management.case_submission",
        title: "common.case_submission",
        type: "basic",
        link: `/case-for-agency`,
        meta: {
          permission: {
            slug: "case-submission::accessUrl",
          },
        },
      },
    ], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
  },
  {
    id: "document",
    title: "common.documents",
    // tooltip: 'Report',
    type: "collapsable",
    icon: "document",
    isSvgIcon: true,
    children: [
      {
        id: "general.document",
        title: "common.my_documents",
        type: "basic",
        link: `/document`,
        meta: {
          permission: {
            slug: "document::accessUrl",
          },
        },
      },
      {
        id: "general.document_template",
        title: "common.templates",
        type: "basic",
        link: `/document-template`,
        meta: {
          permission: {
            slug: "template::accessUrl",
          },
        },
      },
      
    ],
  },
  {
    id: "general",
    title: "common.general",
    // tooltip: 'Report',
    type: "collapsable",
    icon: "general",
    isSvgIcon: true,
    children: [
      
      {
        id: "general.item",
        title: "common.properties",
        type: "basic",
        link: `/item`,
        meta: {
          permission: {
            slug: "item::accessUrl",
          },
        },
      },
      {
        id: "general.kyc",
        title: 'common.kyc_aml',
        classes:{
          title:'uppercase'
        },
        type: "basic",
        link: `/kyc`,
        meta: {
          permission: {
            slug: "kyc::accessUrl",
          },
        },
      },
      // {
      //   id: "general.ecdd",
      //   title: "common.ecdd",
      //   classes:{
      //     title:'uppercase'
      //   },
      //   type: "basic",
      //   link: `/huttons/ecdd`,
      //   meta: {
      //     permission: {
      //       slug: "ecdd-hutton::accessUrl",
      //     },
      //   },
      // },
      {
        id: "general.ecdd",
        title: "common.ecdd",
        classes:{
          title:'uppercase'
        },
        type: "basic",
        link: `/ecdd`,
        meta: {
          permission: {
            slug: "ecdd-hutton::accessUrl",
          },
        },
      },
    ],
  },
  {
    id: "accounts_receivable",
    title: "common.accounts_receivable",
    // tooltip: 'Report',
    type: "collapsable",
    icon: "invoice",
    isSvgIcon: true,
    children: [
      {
        id: "accounts_receivable.invoices",
        title: "common.invoices",
        type: "basic",
        link: `/invoice`,
        meta: {
          permission: {
            slug: "invoice::accessUrl",
          },
        },
      },
      {
        id: "accounts_receivable.receipt",
        title: "common.receipts",
        type: "basic",
        link: `/receipt`,
        meta: {
          permission: {
            slug: "receipt::accessUrl",
          },
        },
      },
    ],
  },
  {
    id: "accounts_payable",
    title: "common.accounts_payable",
    // tooltip: 'Report',
    type: "collapsable",
    icon: "receipt",
    children: [
      {
        id: "accounts_payable.bills_to_pay",
        title: "common.bills_to_pay",
        type: "basic",
        link: `/bills-to-pay`,
        meta: {
          permission: {
            slug: "supplier-invoice::accessUrl",
          },
        },
      },
    ],
  },
  {
    id: "business_partners",
    title: "common.business_partners",
    // tooltip: 'common.business_partners',
    type: "collapsable",
    icon: "person",
    children: [
      {
        id: "business_partners.clients",
        title: "common.clients",
        type: "basic",
        link: `/client`,
        meta: {
          permission: {
            slug: "client::accessUrl",
          },
        },
      },
      {
        id: "business_partners.ext_cobroke_salesperson",
        title: "common.ext_cobroke_salesperson",
        type: "basic",
        link: `/cobroke-agent`,
        meta: {
          permission: {
            slug: "co-broke-agent::accessUrl",
          },
        },
      },
      {
        id: "case_submission_management.vendor",
        title: "common.vendor",
        type: "basic",
        link: `/vendor`,
        meta: {
          permission: {
            disabled:true
          },
        },
      },
    ],
  },
  {
    id: "human_resource",
    title: "common.human_resource",
    // tooltip: 'common.human_resource',
    type: "collapsable",
    icon: "groups",
    children: [
      {
        id: "human_resource.salesperson",
        title: "common.int_salesperson",
        type: "basic",
        link: `/salesperson`,
        meta: {
          permission: {
            slug: "salesperson::accessUrl",
          },
        },
      },
      {
        id: "human_resource.commission_distribution",
        title: "common.commission_distribution",
        type: "basic",
        link: `/commission-distribution`,
        meta: {
          permission: {
            slug: "commission-distribution::accessUrl",
          },
        },
      },
      {
        id: "human_resource.commission_split_matrix",
        title: "common.commission_split_matrix",
        type: "basic",
        link: `/commission-split-matrix`,
        meta: {
          permission: {
            slug: "commission-split-matrix::accessUrl",
          },
        },
      },
      {
        id: "human_resource.promotion_matrix",
        title: "common.promotion_matrix",
        type: "basic",
        link: `/promotion-matrix`,
        meta: {
          permission: {
            slug: "promotion-matrix::accessUrl",
          },
        },
      },
    ],
  },
  // {
  //   id: "reports",
  //   title: "reports",
  //   type: "basic",
  //   icon: "reports",
  //   isSvgIcon:true,
  //   link: "/reports",
  //   meta: {
  //     permission: {
  //       slug: "dashboard::accessUrl",
  //     },
  //   },
  // },
  {
    id: "settings",
    title: "common.settings",
    // tooltip: 'common.settings',
    type: "basic",
    icon: "settings",
    link: `/setting`,
    meta: {
      permission: {
        slug:collectSlugs(navigationSettings)
      },
    },
  },
];
export function collectSlugs(data:NavigationItem[]):string[]{
  const res = new Set<string>()
  function collect(items:NavigationItem[]){
    items.forEach(item => {
      if(item.meta?.permission?.slug){
        const slugs = Array.isArray(item.meta.permission.slug) ? item.meta.permission.slug : [item.meta.permission.slug]
        slugs.forEach(slug =>{
          res.add(slug)
        })        
      }else if(item.children && item.children.length > 0){
        collect(item.children)
      }
    })
  }
   collect(data);
   return Array.from(res.values())
}


export const compactNavigation: NavigationItem[] = [
  {
    id: "dashboard",
    title: "Dashboard",
    tooltip: "Dashboard",
    type: "aside",
    icon: "dashboard",
    link: "/dashboard",
    children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
  },
  {
    id: "case_submission_management",
    title: "common.case_submission",
    tooltip: "common.case_submission",
    type: "aside",
    icon: "my-case",
    isSvgIcon: true,
    children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
  },
  {
    id: "general",
    title: "common.general",
    tooltip: "common.general",
    type: "aside",
    icon: "document",
    isSvgIcon: true,
    children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
  },
  {
    id: "accounts_receivable",
    title: "common.accounts_receivable",
    tooltip: "common.accounts_receivable",
    type: "aside",
    icon: "invoice",
    isSvgIcon: true,
    children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
  },
  {
    id: "accounts_payable",
    title: "common.accounts_payable",
    tooltip: "common.accounts_payable",
    type: "aside",
    icon: "receipt",
    children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
  },
  {
    id: "business_partners",
    title: "common.business_partners",
    tooltip: "common.business_partners",
    type: "aside",
    icon: "person",
    children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
  },
  {
    id: "human_resource",
    title: "common.human_resource",
    tooltip: "common.human_resource",
    type: "aside",
    icon: "groups",
    children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
  },
  // {
  //   id: "reports",
  //   title: "Reports",
  //   tooltip: "Reports",
  //   type: "aside",
  //   icon: "reports",
  //   isSvgIcon:true,
  //   link: "/reports",
  //   children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
  // },
  {
    id: "settings",
    title: "common.settings",
    tooltip: "common.settings",
    type: "aside",
    icon: "settings",
    link: `/setting`,
    children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
  },
];
export const compactNavigationSeting: NavigationItem[] = [
  {
    id: "profile",
    title: "profile",
    tooltip: "profile",
    type: "aside",
    icon: "manage_accounts",
    link: `/setting/profile`,
    children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
  },
  {
    id: "access_management",
    title: "common.access_management",
    tooltip: "common.access_management",
    type: "aside",
    icon: "admin_panel_settings",
    children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
  },
  {
    id: "org_bu",
    title: "common.org_bu",
    tooltip: "common.org_bu",
    type: "aside",
    icon: 'building',
    isSvgIcon: true,
    children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
  },
  {
    id: "settings",
    title: "common.settings",
    tooltip: "common.settings",
    type: "aside",
    icon: "settings",
    children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
  },
];
