import { SelectionModel } from '@core/cdk/collections';
import {
    map,
    startWith,
    switchMap,
    filter,
    debounceTime,
    takeUntil,
} from 'rxjs/operators';
import { ClientApiService } from '@app/shared/services/client-api.service';
import { ClientModel } from '@app/shared/models/client.model';
import {
    Component,
    EventEmitter,
    OnInit,
    Output,
    ViewChild,
    ElementRef,
    OnDestroy,
    Input,
    OnChanges,
    SimpleChanges,
    DoCheck,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';

import { PolicyService } from '@app/modules/permission/policy.service';
import { ClientIndividualCreateModalComponent } from '@app/modules/client/client-individual-create-modal/client-individual-create-modal.component';
import { ClientCompanyCreateModalComponent } from '@app/modules/client/client-company-create-modal/client-company-create-modal.component';

@Component({
    selector: 'app-client-search',
    templateUrl: './client-search.component.html',
    styleUrls: ['./client-search.component.scss'],
})
export class ClientSearchComponent implements OnInit, DoCheck, OnDestroy {
    @Output() selectionChange = new EventEmitter<ClientModel>();
    @Input() optionDisabled = new SelectionModel<number>(true);
    @Input() partyProfile: 'normal' | 'ecdd' = 'normal';

    @ViewChild('inputSearch') inputSearch: ElementRef;
    clientCtrl = new FormControl();
    filteredClients: Observable<ClientModel[]>;

    clientDisabledSelection = new SelectionModel<number>(true);
    unsubscribeAll = new Subject<any>();

    clients: ClientModel[] = [];
    filters: ClientModel[] = [];
    constructor(
        private clientApiService: ClientApiService,
        private dialog: MatDialog,
        private policyService: PolicyService
    ) {
        this.clientCtrl.valueChanges
            .pipe(
                startWith(''),
                debounceTime(300),
                map((val) => {
                    if (val instanceof ClientModel) {
                        return '';
                    }
                    return val;
                }),
                switchMap((keyword) => {
                    const query = {
                        key: keyword,
                        status: 'active',
                    };

                    console.log('ECDD or Normal: ', this.partyProfile);
                    // if (this.partyProfile === 'ecdd') {
                        // query['hasECDDStatus'] = ['approved', 'auto-approved'];
                    // }

                    return this.clientApiService.search(query, {
                        loader: false,
                    });
                }),
                map((data) => data.results), // filter
                takeUntil(this.unsubscribeAll)
            )
            .subscribe((items) => {
                this.clients = items;
                this.filters = this.clients.filter(
                    (item) => !this.optionDisabled.isSelected(item.id)
                );
            });

        this.clientCtrl.valueChanges
            .pipe(
                filter((val) => val instanceof ClientModel),
                takeUntil(this.unsubscribeAll)
            )
            .subscribe((client) => {
                this.selectionChange.emit(client);
            });
    }

    ngDoCheck(): void {
        this.filters = this.clients.filter(
            (item) => !this.optionDisabled.isSelected(item.id)
        );
    }

    ngOnDestroy(): void {
        this.unsubscribeAll.next();
        this.unsubscribeAll.complete();
        this.selectionChange.complete();
    }

    ngOnInit(): void {}

    displayWith(val: ClientModel): string {
        return '';
    }

    clear(evt: MouseEvent): void {
        evt.stopPropagation();
        this.clientCtrl.reset();
        this.inputSearch.nativeElement.focus();
    }

    createIndividual() {
        this.dialog
            .open(ClientIndividualCreateModalComponent, {
                width: '984px',
                maxHeight: '100%',
                disableClose: true,
            })
            .afterClosed()
            .subscribe((data: ClientModel) => {
                if (!data) {
                    return;
                }

                this.selectionChange.emit(data);
            });
    }

    createCompany() {
        this.dialog
            .open(ClientCompanyCreateModalComponent, {
                width: '984px',
                maxHeight: '100%',
                disableClose: true,
            })
            .afterClosed()
            .subscribe((data: ClientModel) => {
                if (!data) {
                    return;
                }

                this.selectionChange.emit(data);
            });
    }

    get allowCreate(): boolean {
        return this.policyService.can('client::create');
    }
}
