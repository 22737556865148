<div class="layout__main-modal">
    <div class="dialog-title">
        <div>{{'item.add_new_property' | translate}}</div>
        <button type="button" mat-icon-button (click)="close()">
            <mat-icon class="icon-close">close</mat-icon>
        </button>
    </div>
    <mat-dialog-content class="dialog-content">
        <form id="my-form" #ngForm="ngForm" [formGroup]="form" (ngSubmit)="submit()">
            <mat-form-field class="full-width">
                <mat-label>{{ 'item.category' | translate | titlecase}}</mat-label>
                <input type="text" placeholder="{{ 'item.enter_category' | translate }}"
                    matInput formControlName="category" [matAutocomplete]="auto" required>
    
                <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                    <mat-option *ngFor="let option of categoriesFilter" [value]="option">
                        {{option}}
                    </mat-option>
                </mat-autocomplete>
                <mat-error
                    *ngIf="form.controls.category.errors && form.controls.category.errors.required">
                    {{ 'validator.required' | translate}}
                </mat-error>
                <mat-error
                    *ngIf="form.controls.category.errors && form.controls.category.errors.maxlength">
                    {{ 'validator.max_length' | translate : {value:
                    form.controls.category.errors.maxlength.requiredLength} }}
                </mat-error>
            </mat-form-field>
    
            <mat-form-field class="full-width">
                <mat-label>{{ 'item.type' | translate | titlecase}}</mat-label>
                <mat-select required placeholder="{{ 'common.search_item' | translate }}"
                    formControlName="type" name="type" #singleSelect>
                    <mat-option>
                        <ngx-mat-select-search [noEntriesFoundLabel]="' '" [formControl]="typeFilterCtrl"
                            placeholderLabel="Search...">
                        </ngx-mat-select-search>
                    </mat-option>
    
                    <mat-option *ngFor="let type of typesFilter" [value]="type.key">
                        {{type.value}}
                    </mat-option>
                </mat-select>
                <mat-error
                    *ngIf="form.controls.type.errors && form.controls.type.errors.required">
                    {{ 'validator.required' | translate}}
                </mat-error>
                <mat-error
                    *ngIf="form.controls.type.errors && form.controls.type.errors.maxlength">
                    {{ 'validator.max_length' | translate : {value:
                    form.controls.type.errors.maxlength.requiredLength} }}
                </mat-error>
            </mat-form-field>
    
            <mat-form-field class="full-width">
                <mat-label>{{
                    'item.item_name_label' | translate | titlecase
                    }}</mat-label>
                <input autocomplete="off" formControlName="name" matInput
                    placeholder="{{'item.item_name_placeholder' | translate}}" type="text"
                    required />
    
                <mat-error *ngIf="form.controls.name.errors && form.controls.name.errors.required">
                    {{
                    'validator.required' | translate
                    }}</mat-error>
                <mat-error *ngIf="form.controls.name.errors && form.controls.name.errors.maxlength">
                    {{
                    'validator.max_length' | translate : {value:
                    form.controls.name.errors.maxlength.requiredLength}
                    }}</mat-error>
            </mat-form-field>

            <div class="full-width" fxLayout="column">
                <h3>{{'item.property_address' | translate}}</h3>
                <mat-form-field appearance="outline">
                    <mat-select
                    placeholder="{{ 'my_info.country_placeholder' | translate }}"
                    formControlName="countryCode"
                    >
                        <mat-option>
                            <ngx-mat-select-search
                                [noEntriesFoundLabel]="' '"
                                [formControl]="countryControl"
                                placeholderLabel="{{
                                    ('common.search' | translate) + '...'
                                }}"
                            >
                            </ngx-mat-select-search>
                        </mat-option>

                        <mat-option *ngFor="let country of countries" [value]="country.countryCode" (click)="openPostalBoard(country)">
                            {{ country.country }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <div *ngIf="isOpenPostalBoard" [formGroup]="singaporeAddressForm" class="address-info" fxLayout="row wrap" fxLayoutAlign="space-between start">
                    <mat-form-field class="custom-height-form-field mt-3 mb-5" style="height: 52px; width: 100%;">
                        <input autocomplete="off" formControlName="searchPostalCodeKey" matInput
                            placeholder="Search by postal code, address..." type="text"
                            [matAutocomplete]="postalCode" />
                        <span matPrefix>
                            <mat-icon>search</mat-icon>
                        </span>
                        <mat-autocomplete autoActiveFirstOption #postalCode="matAutocomplete"
                            [displayWith]="displayWith">
                            <mat-option *ngFor="let option of oneMapResults" [value]="option"
                                [matTooltip]="option.ADDRESS">
                                {{option.ADDRESS}}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                    <mat-form-field fxFlex="48">
                        <mat-label>{{ 'singapore_address.block_number' | translate | titlecase}}
                        </mat-label>
                        <input autocomplete="off" formControlName="blockNumber" matInput required
                            placeholder="{{ 'singapore_address.enter_block_number' | translate }}"
                            type="text">

                        <mat-error
                            *ngIf="singaporeAddressForm.controls.blockNumber.errors && singaporeAddressForm.controls.blockNumber.errors.required">
                            {{ 'validator.required' | translate}}
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field fxFlex="48">
                        <mat-label>{{ 'singapore_address.street_name' | translate | titlecase}}
                        </mat-label>
                        <input autocomplete="off" formControlName="streetName" matInput required
                            placeholder="{{ 'singapore_address.enter_street_name' | translate }}"
                            type="text">

                        <mat-error
                            *ngIf="singaporeAddressForm.controls.streetName.errors && singaporeAddressForm.controls.streetName.errors.required">
                            {{ 'validator.required' | translate}}
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field fxFlex="22">
                        <mat-label>{{ 'singapore_address.floor_number' | translate | titlecase}}
                        </mat-label>
                        <input autocomplete="off" formControlName="floorNumber" matInput
                            placeholder="{{ 'singapore_address.enter_floor_number' | translate }}"
                            type="text">
                    </mat-form-field>

                    <mat-form-field fxFlex="22">
                        <mat-label>{{ 'singapore_address.unit_number' | translate | titlecase}}
                        </mat-label>
                        <input autocomplete="off" formControlName="unitNumber" matInput
                            placeholder="{{ 'singapore_address.enter_unit_number' | translate }}"
                            type="text">
                    </mat-form-field>

                    <mat-form-field fxFlex="48">
                        <mat-label>{{ 'singapore_address.building_name' | translate | titlecase}}
                        </mat-label>
                        <input autocomplete="off" formControlName="buildingName" matInput
                            placeholder="{{ 'singapore_address.enter_building_name' | translate }}"
                            type="text">
                    </mat-form-field>

                    <mat-form-field fxFlex="48">
                        <mat-label>{{ 'singapore_address.postal_code' | translate | titlecase}}
                        </mat-label>
                        <input autocomplete="off" formControlName="postalCode" matInput required
                            placeholder="{{ 'singapore_address.enter_postal_code' | translate }}"
                            type="text">
                        <mat-error
                            *ngIf="singaporeAddressForm.controls.postalCode.errors && singaporeAddressForm.controls.postalCode.errors.required">
                            {{ 'validator.required' | translate}}
                        </mat-error>
                        <mat-error
                            *ngIf="singaporeAddressForm.controls.postalCode.errors && singaporeAddressForm.controls.postalCode.errors.maxlength">
                            {{
                            'validator.max_length' | translate : {value:
                                singaporeAddressForm.controls.postalCode.errors.maxlength.requiredLength}
                            }}</mat-error>
                    </mat-form-field>

                    <mat-form-field fxFlex="48">
                        <mat-label>{{ 'singapore_address.district' | translate | titlecase}}
                        </mat-label>
                        <input autocomplete="off" formControlName="dictrict" matInput 
                        placeholder="{{ 'singapore_address.enter_district' | translate }}"
                            type="text">
                        <!-- <mat-error
                            *ngIf="form.controls.dictrict.errors && form.controls.dictrict.errors.required">
                            {{ 'validator.required' | translate}}
                        </mat-error> -->
                    </mat-form-field>
                </div>
                <div *ngIf="!isOpenPostalBoard" [formGroup]="otherAddressForm" class="address-info" fxLayout="row wrap" fxLayoutAlign="space-between start">
                    <mat-form-field fxFlex="100">
                        <mat-label>{{ 'Address' | translate | titlecase}}
                        </mat-label>
                        <input autocomplete="off" formControlName="addressLine" matInput required
                            placeholder="{{ 'singapore_address.enter_address' | translate }}"
                            type="text">

                        <mat-error
                            *ngIf="otherAddressForm.controls.addressLine.errors && otherAddressForm.controls.addressLine.errors.required">
                            {{ 'validator.required' | translate}}
                        </mat-error>
                        <mat-error
                            *ngIf="otherAddressForm.controls.addressLine.errors && otherAddressForm.controls.addressLine.errors.maxlength">
                            {{
                            'validator.max_length' | translate : {value:
                                otherAddressForm.controls.addressLine.errors.maxlength.requiredLength}
                            }}
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
        </form>
    </mat-dialog-content>
    <mat-dialog-actions class="dialog-actions" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="20px">
        <button type="submit" mat-flat-button color="primary" form="my-form" >
            {{ 'common.save_changes' | translate }}
        </button>
    </mat-dialog-actions>
</div>
