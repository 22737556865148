import { BusinessUnitModel, BusinessUnitInterface } from '@app/shared/models/business-unit.model';
import { PromotionMatrixInterface, PromotionMatrixModel } from './promotion-matrix.model';
import { UserModel } from '@app/shared/models/user.model';
import { UserInterface } from './user.model';
import { BaseModel } from '@app/core/models/base.model';
import { Exclude, Expose, Type } from 'class-transformer';
import { AddressInterface } from './address.model';
import { PhoneNumber, PhoneNumberInterface } from '@app/modules/shared/phone-number/phone-number.component';


export interface IVendor {
    id: number;
    code:string;
    uenNumber:string;
    name:string;
    email?:string;
    logo?:string
    phone?:PhoneNumberInterface;
    postalCode?: string;
    unitNumber?: string;
    blockNumber?: string;
    streetName?: string;
    buildingName?: string;
    floorNumber?: string;
    dictrict?:string;
    contact?:{
        name?:string;
        title?:string;
        email?:string;
        phone?:PhoneNumberInterface
    }
}




@Exclude()
export class VendorModel extends BaseModel implements IVendor {
    @Expose()
    id: number;

    @Expose()
    code:string;

    @Expose()
    name:string;

    @Expose()
    uenNumber:string;

    @Expose()
    logo:string
    @Expose()
    email:string

    @Expose()
    phone:PhoneNumberInterface
    
    @Expose()
    postalCode?: string;
    @Expose()
    unitNumber?: string;
    @Expose()
    blockNumber?: string;
    @Expose()
    streetName?: string;
    @Expose()
    buildingName?: string;
    @Expose()
    floorNumber?: string;
    @Expose()
    dictrict: string;

    @Expose()
    contact?:{
        name?:string;
        title?:string;
        email?:string;
        phone?:PhoneNumberInterface
    }
}
