import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { SVG_ICONS } from '@core/data/custom-svg-icon.data';
import { MESSAGE_TYPE } from '@shared/models/message.model';
import { RouterService } from '@core/services/router.service';
import { GlobalService } from '@core/services/global.service';
import { GrecaptchaService } from '@shared/grecaptcha';
import { Angulartics2GoogleGlobalSiteTag } from 'angulartics2/gst';
import { AppConstant } from '@app/app.constant';
import { SEOService } from '@core/services/seo.service';
import * as moment from 'moment-timezone';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map, takeUntil } from 'rxjs/operators';
import { SubscriptionService } from './core/services/subscription.service';
import { MatDialog } from '@angular/material/dialog';
import * as Sentry from "@sentry/angular";
import { RequestSubscriptionModalComponent } from './modules/shared/request-subscription-modal/request-subscription-modal.component';
import { Subject } from 'rxjs';
import { UserModel } from './shared/models/user.model';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
    loading = false;
    timezone = AppConstant.DEFAULT_TIMEZONE;
    private _unsubscribeAll$ = new Subject<void>();
    constructor(
        private angulartics: Angulartics2GoogleGlobalSiteTag,
        private translateService: TranslateService,
        private toastrService: ToastrService,
        private globalService: GlobalService,
        private routerService: RouterService,
        private seoService: SEOService,
        private titleService: Title,
        private iconRegistry: MatIconRegistry,
        private sanitizer: DomSanitizer,
        private changeDetectorRef: ChangeDetectorRef,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private subscriptionService: SubscriptionService,
        private grecaptchaService: GrecaptchaService,
        private dialog: MatDialog
    ) {
        this.angulartics.startTracking();
        // this language will be used as a fallback when a translation isn't found in the current language
        this.translateService.setDefaultLang('en');
        // the lang to use, if the lang isn't available, it will use the current loader to get them

        this.addIcons();

        // set default timezone
        if (this.timezone) {
            this.setTimezone(this.timezone);
        }

        // Set page title
        this._setPageTitle();
        this._registerSquarefootApi();
    }
    ngOnDestroy(): void {
        this._unsubscribeAll$.next();
        this._unsubscribeAll$.complete()
    }

    ngOnInit(): void {
        this.globalService.message.pipe(
            takeUntil( this._unsubscribeAll$)
        ).subscribe((message) => {
            let msg = '';
            if (message.message) {
                msg = this.translateService.instant(message.message);
            }
            const options = message.options ?? null;
            switch (message.type) {
                case MESSAGE_TYPE.error: {
                    this.toastrService.error(msg, message.title, {
                        positionClass: 'toast-bottom-right',
                        enableHtml: true,
                        ...options
                    });
                    break;
                }
                case MESSAGE_TYPE.success: {
                    this.toastrService.success(msg, message.title, {
                        positionClass: 'toast-success-custom',
                        ...options
                    });
                    break;
                }
                case MESSAGE_TYPE.warning: {
                    this.toastrService.warning(msg, message.title, {
                        positionClass: 'toast-bottom-right',
                        ...options
                    });
                    break;
                }
                case MESSAGE_TYPE.info: {
                    this.toastrService.info(msg, message.title, {
                        positionClass: 'toast-bottom-right',
                        ...options
                    });
                    break;
                }
                default:
                    this.toastrService.show(msg, message.title, {
                        positionClass: 'toast-bottom-right',
                        ...options
                    });
            }
        });
        this.globalService.loader.status.pipe(
            takeUntil( this._unsubscribeAll$)
        ).subscribe(status => {
            this.loading = status;
            this.changeDetectorRef.detectChanges();
        });
        this.globalService.storage
        .watch(AppConstant.GLOBAL_STORAGE.USER).pipe(
            takeUntil( this._unsubscribeAll$)
        ).subscribe((user:UserModel) => {
            const payload = user ? {
                userId: user.id,
                name: user.name,
                email: user.email
              } : null;
              Sentry.setUser(payload)
        })
        this.routerService.loadRouting();
        this.seoService.listenRouterChange();
    }

    addIcons() {
        SVG_ICONS.forEach(item => {
            this.iconRegistry.addSvgIcon(
                item.key,
                this.sanitizer.bypassSecurityTrustResourceUrl(item.path)
            );
        });
    }


    setTimezone(timezone: string) {
        moment.tz.setDefault(timezone);
    }

    private _checkSubscriptionExpired() {
        if (this.subscriptionService.free()) {
            this.dialog.open(RequestSubscriptionModalComponent, {
                width: '100%',
                height: '100%',
                maxWidth: '100%',
                autoFocus: false,
                panelClass: 'upgrade-subscription-dialog',
                data: {
                    isHideCloseButton: true,
                    isHideLogoutButton: false
                }
            });
        }
    }

    private _setPageTitle() {
        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd),
            map(() => {
                let child = this.activatedRoute.firstChild;
                while (child) {
                    if (child.firstChild) {
                        child = child.firstChild;
                    } else if (child.snapshot.data && child.snapshot.data['title']) {
                        return child.snapshot.data['title'];
                    } else {
                        return null;
                    }
                }
                return null;
            })
        ).subscribe((data: any) => {
            if (data) {
                this.titleService.setTitle(data);
            } else {
                if (!AppConstant.APP_PRODUCTION) {
                    this.titleService.setTitle('Redoc [' + AppConstant.APP_ID.toUpperCase() + ']');
                } else {
                    this.titleService.setTitle('Redoc');
                }
            }

            this._checkSubscriptionExpired();
        });
    }

    private _registerSquarefootApi() {
        const node = document.createElement('script');
        node.src = `//www.squarefoot.com.sg/component/sqftapi?token=${AppConstant.SQUAREFOOT_API_TOKEN}&task=js`;
        document.getElementsByTagName('head')[0].appendChild(node);
    }
}
