import { Directive, HostListener, ElementRef, Input } from '@angular/core';

@Directive({
    selector: 'input[appNumberOnly]'
})
export class NumberOnlyDirective {

    constructor(
        private inputElement: ElementRef
    ) {
        this.oldValue = this.convertStringToNumber(this.convertStringToNumberString(this.inputElement.nativeElement.value));
    }
    @Input() minimun;

    private navigationKeys = [
        'Backspace',
        'Delete',
        'Tab',
        'Escape',
        'Enter',
        'Home',
        'End',
        'ArrowLeft',
        'ArrowRight',
        'Clear',
        'Copy',
        'Paste',
    ];
    private oldValue: number;
    @HostListener('paste', ['$event'])
    onPaste(event: ClipboardEvent) {
        event.preventDefault();
        const pastedInput: string = event.clipboardData.getData('text/plain').replace(/\D/g, ''); // get a digit-only string
        document.execCommand('insertText', false, pastedInput);
    }
    @HostListener('drop', ['$event'])
    onDrop(event: DragEvent) {
        event.preventDefault();
        const textData = event.dataTransfer.getData('text').replace(/\D/g, '');
        this.inputElement.nativeElement.focus();
        document.execCommand('insertText', false, textData);
    }
    @HostListener('keydown', ['$event']) onKeyDown(event: KeyboardEvent) {
        // event.stopPropagation();
        // event.preventDefault();
        if (
            this.navigationKeys.indexOf(event.key) > -1 || // Allow: navigation keys: backspace, delete, arrows etc.
            ((event.key === 'a' || event.code === 'KeyA') && event.ctrlKey === true) || // Allow: Ctrl+A
            ((event.key === 'c' || event.code === 'KeyC') && event.ctrlKey === true) || // Allow: Ctrl+C
            ((event.key === 'v' || event.code === 'KeyV') && event.ctrlKey === true) || // Allow: Ctrl+V
            ((event.key === 'x' || event.code === 'KeyX') && event.ctrlKey === true) || // Allow: Ctrl+X
            ((event.key === 'a' || event.code === 'KeyA') && event.metaKey === true) || // Allow: Cmd+A (Mac)
            ((event.key === 'c' || event.code === 'KeyC') && event.metaKey === true) || // Allow: Cmd+C (Mac)
            ((event.key === 'v' || event.code === 'KeyV') && event.metaKey === true) || // Allow: Cmd+V (Mac)
            ((event.key === 'x' || event.code === 'KeyX') && event.metaKey === true) // Allow: Cmd+X (Mac)
        ) {
            // let it happen, don't do anything
            return;
        }
        const currentData = this.forecastValue(event.key);
        const formatedData = this.convertStringToNumberString(currentData);
        if (currentData !== formatedData) {
            event.preventDefault();
        }
    }
    @HostListener('input', ['$event']) onInput(event: InputEvent) {
        event.stopPropagation();
        event.preventDefault();
        const currentData = this.inputElement.nativeElement.value;
        const nonLeadingZeros = this.removeLeadingZeros(currentData);
        const numberStringData = this.convertStringToNumberString(nonLeadingZeros);
        const numberData = this.convertStringToNumber(numberStringData);
        if (numberData < this.minimun) {
            this.inputElement.nativeElement.value = this.oldValue;
        } else {
            this.inputElement.nativeElement.value = nonLeadingZeros;
        }

        this.oldValue = this.inputElement.nativeElement.value;
    }

    private forecastValue(key: string): string {
        const inputNativeElement = this.inputElement.nativeElement as HTMLInputElement;
        const selectionStart = inputNativeElement.selectionStart;
        const selectionEnd = inputNativeElement.selectionEnd;
        const oldValue = inputNativeElement.value;
        const selection = oldValue.substring(selectionStart, selectionEnd);
        return selection
            ? oldValue.replace(selection, key)
            : oldValue.substring(0, selectionStart) +
            key +
            oldValue.substring(selectionStart);
    }
    convertStringToNumberString(input: string): string {
        return input.replace(/[^0-9]*/g, '');  /** replace character to number */
    }
    removeLeadingZeros(input: string): string {
        return input.replace(/^0+/g, '');  /** remove leading zeros */
    }
    convertStringToNumber(input: string): number {
        return parseInt(input, 10);
    }
}
