

import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AppConstant } from '@app/app.constant';
import { GlobalService } from '@app/core/services/global.service';
import { SubscriptionService } from '@app/core/services/subscription.service';
import { MESSAGE_TYPE } from '@app/shared/models/message.model';
import { SubscriptionModel, SubscriptionPlanModel } from '@app/shared/models/subscription.model';
import { PaymentApiService } from '@app/shared/services/payment-api.service';
import { SubscriptionApiService } from '@app/shared/services/subscription-api.service';
import { Subject } from 'rxjs';
import { map, startWith, take, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-request-subscription',
    templateUrl: './request-subscription-modal.component.html',
    styleUrls: ['./request-subscription-modal.component.scss']
})

export class RequestSubscriptionModalComponent implements OnInit {
    subscription: SubscriptionModel;
    plans: SubscriptionPlanModel[] = [];
    currency = AppConstant.CURRENCY_SINGAPORE;
    saveUpPercentage = 0;
    inputCtrl = new FormControl();
    unsubscribeAll = new Subject();
    interval: 'month' | 'year';
    isHideCloseButton = false;
    isHideLogoutButton = true;

    constructor(
        private dialogRef: MatDialogRef<RequestSubscriptionModalComponent>,
        private router: Router,
        private paymentApiService: PaymentApiService,
        private globalService: GlobalService,
        private subscriptionApiService: SubscriptionApiService,
        private subscriptionService: SubscriptionService,
        @Inject(MAT_DIALOG_DATA) public modalData: { isHideCloseButton: boolean; isHideLogoutButton: boolean }
    ) {
        this.isHideCloseButton = this.modalData?.isHideCloseButton ?? false;
        this.isHideLogoutButton = this.modalData?.isHideLogoutButton ?? true;
    }

    ngOnInit() {
        this.getPublicPlan();
        this.inputCtrl.valueChanges.pipe(
            startWith(false),
            takeUntil(this.unsubscribeAll)
        ).subscribe(checked => {
            if (checked) {
                this.interval = 'year';
            } else {
                this.interval = 'month';
            }

        });
        this.globalService.storage.watch(AppConstant.GLOBAL_STORAGE.MY_SUBSCRIPTION)
            .pipe(take(1))
            .subscribe(subscription => {
                this.subscription = subscription;
            });
    }

    close() {
        this.dialogRef.close();
    }

    getPublicPlan() {
        this.subscriptionApiService.getPublicPlan().subscribe(rs => {
            this.plans = rs.filter(v => v.isPublished);
            this.getSavingPercentage(this.plans);
        });
    }

    getSavingPercentage(plans: SubscriptionPlanModel[]) {
        const arr = [];

        for (let i = 0; i < plans.length; i++) {
            const plan = plans[i];
            const yearPrice = plan.pricePerMonthOfYear;
            const monthPrice = plan.prices.month;
            arr.push(this.calculateSavingPercentage(monthPrice, yearPrice));
        }
        this.saveUpPercentage = Math.max.apply(Math, arr);
    }

    calculateSavingPercentage(monthlyPrice: number, anuallyPrice: number) {
        const percentage = 100 - (anuallyPrice / monthlyPrice) * 100;
        return Math.round(percentage);
    }

    upgrade(plan: SubscriptionPlanModel, interval: 'month' | 'year' = 'month') {
        this.paymentApiService.getPaymentMethod()
            .pipe(map(rs => rs.filter(v => v.status === 'confirmed')))
            .subscribe(paymentMethods => {
                if (paymentMethods && paymentMethods.length) {
                    this.changeOtherPlan(plan, interval);
                } else {
                    this.addPaymentMethod(interval);
                }
            });
    }

    addPaymentMethod(interval?: 'month' | 'year') {
        const redirectUrl = this.subscriptionService.getPaymentSuccessUrl(true, interval);
        const cancelUrl = AppConstant.APP_BASE_URL + '/';

        this.paymentApiService.add({
            redirectUrl,
            cancelUrl
        }).subscribe(data => {
            window.location.href = data.url;
        });
    }

    changeOtherPlan(plan: SubscriptionPlanModel, interval: 'month' | 'year' = 'month') {
        this.subscriptionApiService.changeOtherPlan({
            subscriptionId: this.subscription.id,
            planId: plan.id,
            interval
        }).subscribe(data => {
            this.globalService.storage.dispatch(AppConstant.GLOBAL_STORAGE.MY_SUBSCRIPTION, data);
            this.close();
            this.router.navigateByUrl('/setting/billing-info');
        }, error => {
            this.globalService.message.next({
                type: MESSAGE_TYPE.error,
                message: 'Something went wrong!'
            });
        });
    }
}
