import { AppConstant } from '@app/app.constant';

export const CONTENT_BUILDER_STYLE = {
    URL: [
        AppConstant.APP_BASE_URL + '/assets/others/styles24042024.css',
        AppConstant.APP_BASE_URL + '/assets/others/signature-singpass.css',
    ],
    PRINT_OPTIONS: {
        MARGIN: {
            TOP: 60,
            BOTTOM: 60,
            LEFT: 50,
            RIGHT: 50,
        },
    },
};
