<form id="my-form" #ngForm="ngForm" [formGroup]="form" (ngSubmit)="submit()"  class="flex flex-col flex-auto gap-4">
    <div class="flex flex-col md:flex-row md:justify-start md:items-center gap-4 client-type">
        <div class="title">{{ 'client.client_type' | translate }}</div>
        <div class="flex flex-col md:flex-row md:gap-4">
            <mat-checkbox class="example-margin" *ngFor="let role of clientRoles"
                [checked]="roleSelected.isSelected(role.key)" (change)="roleSelected.toggle(role.key)">
                {{role.displayName}}
            </mat-checkbox>
        </div>
    </div>
    <div class="flex flex-col flex-auto">
        <div class="grid lg:grid-cols-2 grid-cols-1  lg:gap-4">
            <div class="flex flex-col gap-4">
                <div class="title">{{ 'common.general_info' | translate }}</div>
                <div class="flex flex-col justify-start" >
                    <div class="flex flex-row justify-start items-center gap-6 mb-4 img-wrapper">
                        <div class="img-preview img-preview--circle w-16 h-16 ">
                            <img appLoadImage [key]="fileUploader?.key">
                        </div>
                        <button type="button" mat-button class="btn-border" (click)="inputFile.click()">
                            {{ 'customer.upload_avatar' | translate }}
                        </button>
                        <input class="input-file" type="file" #inputFile [multiple]="false" appUpload
                            [isUploadImmediately]="true" [maxFiles]="1" [isReplaced]="true"
                            (finishedFile)="onFinishedFile($event)" [allowedMimeType]="allowedMimeType" [customizeFileNameFn]="customizeFileNameFn.bind(this)">
                    </div>
    
                    <mat-form-field >
                        <mat-label>{{ 'customer.primary_client_name' | translate | titlecase}}
                        </mat-label>
                        <input autocomplete="off" #name formControlName="name" matInput required
                            placeholder="{{ 'customer.enter_name' | translate }}" type="text">
                        <mat-error *ngIf="form.controls['name'].hasError('required')">
                            {{ 'validator.required' | translate}}
                        </mat-error>
                        <mat-error
                            *ngIf="form.controls.name.errors && form.controls.name.errors.maxlength">
                            {{
                            'validator.max_length' | translate :
                            {value:form.controls.name.errors.maxlength.requiredLength}
                            }}</mat-error>
                    </mat-form-field>
    
                    <mat-form-field class="full-width">
                        <mat-label>{{ 'common.phone_number' | translate | titlecase}}</mat-label>
                        <app-phone-number formControlName="phone"
                            placeholder="{{ 'common.phone_number_placeholder' | translate }}" required>
                        </app-phone-number>
                        <mat-error *ngIf="form.controls['phone'].hasError('required')">
                            {{ 'validator.required' | translate}}
                        </mat-error>
                        <mat-error
                            *ngIf="form.controls.phone.errors && form.controls.phone.errors.maxlength">{{
                            'validator.max_length' | translate :
                            {value:form.controls.phone.errors.maxlength.requiredLength}
                            }}</mat-error>
                    </mat-form-field>
    
                    <mat-form-field class="full-width">
                        <mat-label>{{ 'common.email' | translate | titlecase}}</mat-label>
                        <input autocomplete="off" formControlName="email" matInput required #email trim
                            placeholder="{{ 'common.email_placeholder' | translate }}" type="text">
                        <mat-error
                            *ngIf="form.controls.email.errors && form.controls.email.errors.maxlength">{{
                            'validator.max_length' | translate :
                            {value:form.controls.email.errors.maxlength.requiredLength}
                            }}</mat-error>
                        <mat-error *ngIf="form.controls['email'].hasError('required')">
                            {{ 'validator.required' | translate}}
                        </mat-error>
                        <mat-error *ngIf="form.controls['email'].hasError('email')">
                            {{ 'validator.email' | translate}}
                        </mat-error>
                        <mat-error *ngIf="form.controls['email'].hasError('existed')">
                            {{ 'validator.existed' | translate}}
                        </mat-error>
                    </mat-form-field>
                    <div class="grid lg:grid-cols-2 grid-cols-1  lg:gap-4">
                        <mat-form-field >
                            <mat-label>{{ 'customer.gender' | translate | titlecase }}</mat-label>
                            <mat-select formControlName="gender">
                                <mat-option value="male">Male</mat-option>
                                <mat-option value="female">Female</mat-option>
                                <mat-option value="other">Other</mat-option>
                            </mat-select>
                        </mat-form-field>
    
                        <mat-form-field >
                            <mat-label>{{ 'customer.salutation' | translate| titlecase }}</mat-label>
                            <mat-select formControlName="salutation">
                                <mat-option value="mr">Mr</mat-option>
                                <mat-option value="ms">Ms</mat-option>
                                <mat-option value="mrs">Mrs</mat-option>
                                <mat-option value="others">Others</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="grid lg:grid-cols-2 grid-cols-1  lg:gap-4">
                        <mat-form-field >
                            <mat-label>{{ 'customer.nric_number' | translate }}</mat-label>
                            <input autocomplete="off" formControlName="nricNumber" matInput #nricNumber
                                placeholder="{{ 'customer.nric_number_placeholder' | translate }}"
                                type="text">
    
                            <mat-error
                                *ngIf="form.controls.nricNumber.errors && form.controls.nricNumber.errors.maxlength">
                                {{
                                'validator.max_length' | translate :
                                {value:form.controls.nricNumber.errors.maxlength.requiredLength}
                                }}</mat-error>
                        </mat-form-field>
                        <mat-form-field >
                            <mat-label>{{ 'customer.passport_number' | translate | titlecase}}</mat-label>
                            <input autocomplete="off" formControlName="passportNumber" matInput #passport
                                placeholder="{{ 'customer.passport_number_placeholder' | translate }}"
                                type="text">
                            <mat-error
                                *ngIf="form.controls.passportNumber.errors && form.controls.passportNumber.errors.maxlength">
                                {{
                                'validator.max_length' | translate :
                                {value:form.controls.passportNumber.errors.maxlength.requiredLength}
                                }}</mat-error>
                        </mat-form-field>
                    </div>
    
                    <mat-form-field class="full-width">
                        <mat-label>{{ 'customer.date_of_birth' | translate }}</mat-label>
                        <input matInput [matDatepicker]="datePicker"
                            placeholder="{{ 'customer.enter_date_of_birth' | translate }}" readonly
                            (click)="datePicker.open()" (focus)="datePicker.open()"
                            formControlName="dateOfBirth">
                        <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
                        <mat-datepicker #datePicker startView="multi-year"></mat-datepicker>
                    </mat-form-field>
    
                    <div class="grid lg:grid-cols-2 grid-cols-1  lg:gap-4">
                        <mat-form-field  >
                            <mat-label>{{ 'customer.residency_status' | translate| titlecase }}</mat-label>
                            <mat-select formControlName="residencyStatus">
                                <mat-option value="citizen">Citizen</mat-option>
                                <mat-option value="pr">PR</mat-option>
                                <mat-option value="foreigner">Foreigner</mat-option>
                            </mat-select>
                        </mat-form-field>
    
                        <mat-form-field >
                            <mat-label>{{ 'customer.nationality' | translate | titlecase}}</mat-label>
                            <input autocomplete="off" formControlName="citizenship" matInput placeholder="{{ 'customer.enter_nationality' | translate }}" type="text">
                        </mat-form-field>
                    </div>
                    <div class="flex flex-col">
                        <mat-checkbox [checked]="isCompanyLead" (change)="onCompanyLeadChange($event)" >Company Lead</mat-checkbox>
                        <mat-form-field *ngIf="isCompanyLead" class="w-full">
                            <mat-label>{{ 'common.vendor' | translate| titlecase }}</mat-label>
                             
                                    <mat-select
                                      matInput
                                     [formControl]="vendorCtrl"
                                    >
                                      <mat-select-trigger> </mat-select-trigger>
                                      <mat-option>
                                        <ngx-mat-select-search
                                          [noEntriesFoundLabel]="' '"
                                          [formControl]="receiverSearcher.searchCtrl"
                                          [searching]="receiverSearcher.loading"
                                          placeholderLabel="Search..."
                                        >
                                        </ngx-mat-select-search>
                                      </mat-option>
                                      <cdk-virtual-scroll-viewport
                                        #receiverSearcher="virtualScrollController"
                                        redVirtualScrollController
                                        [searchWith]="searchWithFn.bind(this)"
                                      >
                                        <ng-container
                                          *cdkVirtualFor="let item of receiverSearcher.results"
                                        >
                                          <mat-option
                                            [value]="item"
                                          >
                                          <div class="flex flex-col w-full overflow-hidden">
                                            <div class="truncate leading-4">{{item?.name}} <span class="text-orange-800 text-theme-gray italic" *ngIf="item?.metadata?.inUse">In Use | {{item.metadata.role | titlecase}}</span></div>
                                            <div class="truncate text-theme-gray leading-4">{{item?.email}}</div>
                                          </div>
                                          </mat-option>
                                        </ng-container>
                                      </cdk-virtual-scroll-viewport>
                                      <mat-option value="createNew">
                                        Create New
                                      </mat-option>
                                    </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div class="flex flex-col">
                <div class="grid lg:grid-cols-2 grid-cols-1 lg:gap-4">
                    <mat-form-field  >
                        <mat-label>{{ 'customer.job_title' | translate| titlecase }}</mat-label>
                        <input autocomplete="off" formControlName="jobTitle" matInput
                            placeholder="{{ 'customer.enter_job_title' | translate }}" type="text">
                    </mat-form-field>
    
                    <mat-form-field >
                        <mat-label>{{ 'customer.company_name' | translate | titlecase}}</mat-label>
                        <input autocomplete="off" formControlName="companyName" matInput
                            placeholder="{{ 'customer.enter_company_name' | translate }}" type="text">
                    </mat-form-field>
                </div>
    
                <div class="grid lg:grid-cols-2 grid-cols-1 lg:gap-4">
                        <mat-form-field  >
                            <mat-label>{{ 'customer.landline' | translate| titlecase }}</mat-label>
                            <input autocomplete="off" formControlName="landline" matInput
                                placeholder="{{ 'customer.enter_landline' | translate }}" type="text">
                        </mat-form-field>
    
                        <mat-form-field  >
                            <mat-label>{{ 'customer.fax' | translate| titlecase }}</mat-label>
                            <input autocomplete="off" formControlName="fax" matInput
                                placeholder="{{ 'customer.enter_fax' | translate }}" type="text">
                        </mat-form-field>
                </div>
    
                <mat-form-field class="full-width">
                    <mat-label>{{ 'customer.country' | translate | titlecase}}</mat-label>
                    <input autocomplete="off" formControlName="nationality" matInput required
                        placeholder="{{ 'customer.enter_country' | translate }}" type="text">
                    <mat-error *ngIf="form.controls['nationality'].hasError('required')">
                        {{ 'validator.required' | translate}}
                    </mat-error>
                </mat-form-field>
                <mat-form-field class="full-width">
                    <mat-label>{{ 'singapore_address.postal_code' | translate| titlecase }}</mat-label>
                    <input autocomplete="off" formControlName="postalCode" matInput
                        placeholder="{{ 'singapore_address.enter_postal_code' | translate }}" required
                        type="text" [matAutocomplete]="postalCode">
    
                    <mat-autocomplete autoActiveFirstOption #postalCode="matAutocomplete"
                        [displayWith]="displayWith">
                        <mat-option *ngFor="let option of oneMapResults" [value]="option"
                            [matTooltip]="option.ADDRESS">
                            {{option.ADDRESS}}
                        </mat-option>
                    </mat-autocomplete>
                    <mat-error *ngIf="form.controls['postalCode'].hasError('required')">
                        {{ 'validator.required' | translate}}
                    </mat-error>
                </mat-form-field>
                <mat-form-field class="full-width">
                    <mat-label>{{ 'singapore_address.block_number' | translate| titlecase }}</mat-label>
                    <input autocomplete="off" formControlName="blockNumber" matInput required
                        placeholder="{{ 'singapore_address.enter_block_number' | translate }}" type="text">
                    <mat-error *ngIf="form.controls['blockNumber'].hasError('required')">
                        {{ 'validator.required' | translate}}
                    </mat-error>
                </mat-form-field>
    
                <mat-form-field class="full-width">
                    <mat-label>{{ 'singapore_address.street_name' | translate| titlecase }}</mat-label>
                    <input autocomplete="off" formControlName="streetName" matInput required
                        placeholder="{{ 'singapore_address.enter_street_name' | translate }}" type="text">
                    <mat-error *ngIf="form.controls['streetName'].hasError('required')">
                        {{ 'validator.required' | translate}}
                    </mat-error>
                </mat-form-field>
                <mat-form-field class="full-width">
                    <mat-label>{{ 'singapore_address.unit_number' | translate| titlecase }}</mat-label>
                    <input autocomplete="off" formControlName="unitNumber" matInput
                        placeholder="{{ 'singapore_address.enter_unit_number' | translate }}" type="text">
                </mat-form-field>
                <mat-form-field class="full-width">
                    <mat-label>{{ 'singapore_address.building_name' | translate| titlecase }}
                    </mat-label>
                    <input autocomplete="off" formControlName="buildingName" matInput
                        placeholder="{{ 'singapore_address.enter_building_name' | translate }}" type="text">
                </mat-form-field>
                <mat-form-field class="full-width">
                    <mat-label>{{ 'singapore_address.district' | translate| titlecase }}
                    </mat-label>
                    <input autocomplete="off" formControlName="dictrict" matInput (keypress)="numberOnly($event)"
                        placeholder="{{ 'singapore_address.enter_district' | translate }}"
                        type="text">
                </mat-form-field>
            </div>
        </div>
    </div>
</form>