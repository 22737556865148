import { SalespersonPolicyCollection } from "./../salesperson/salesperson.policy-collection";
import {
  Injector,
  ModuleWithProviders,
  NgModule,
  Provider,
} from "@angular/core";
import { PermissionDirective } from "@modules/permission/permission.directive";
import { PermissionGuard } from "@modules/permission/permission.guard";
import { BasePolicyCollection } from "@modules/permission/base.policy-collection";
import { PolicyService } from "@modules/permission/policy.service";
import { PolicyDirective } from "@modules/permission/policy.directive";
import { PolicyGuard } from "@modules/permission/policy.guard";
import { DashboardPolicyCollection } from "../dashboard/dashboard.policy-collection";
import { DocumentPolicyCollection } from "../document/document.policy-collection";
import { InvoicePolicyCollection } from "../invoice/invoice.policy-collection";
import { DocumentTemplatePolicyCollection } from "../document-template/document-template.policy-collection";
import { ItemPolicyCollection } from "../item/item.policy-collection";
import { ClientPolicyCollection } from "../client/client.policy-collection";
import { CaseSubmissionAgencyPolicyCollection } from "../case-submission-agency/case-submission-agency.policy-collection";
import { PermissionService } from "./permission.service";
import { CobrokePolicyCollection } from "../cobroke/cobroke.policy-collection";
import { CommisionMatrixPolicyCollection } from "../commission-matrix/commission-matrix.policy-collection";
import { PromotionMatrixPolicyCollection } from "../promotion-matrix/promotion-matrix.policy-collection";
import { CaseSubmissionPersonalPolicyCollection } from "../case-submission-personal/case-submission-personal.policy-collection";
import { CommissionDistributionPolicyCollection } from "../commission-distribution/commission-distribution.policy-collection";
import { KYCPolicyCollection } from "../kyc/kyc.policy-collection";
import { SettingPolicyCollection } from "../setting/setting.policy-collection";
import { ReceiptPolicyCollection } from "../receipt/receipt.policy-collection";
import { SupplierInvoicePolicyCollection } from "../supplier-invoice/supplier-invoice.policy-collection";
import { PayoutPolicyCollection } from "../payout/payout.policy-collection";
import { EcddPolicyCollection } from "../ecdd/ecdd.policy-collection";
import { HasPolicyPipe } from "./policy.pipe";

export interface PolicyProvider<T extends BasePolicyCollection> {
  policy: new (...args) => T;
  deps: (new (...args) => T)[];
}

export type PolicyCollection<T extends BasePolicyCollection> = new (
  ...args
) => T;
export const registeredPolicies = [
  BasePolicyCollection,
  DashboardPolicyCollection,
  {
    policy: DocumentPolicyCollection,
    deps: [PermissionService, InvoicePolicyCollection],
  },
  InvoicePolicyCollection,
  ReceiptPolicyCollection,
  SupplierInvoicePolicyCollection,
  PayoutPolicyCollection,
  DocumentTemplatePolicyCollection,
  ItemPolicyCollection,
  ClientPolicyCollection,
  CaseSubmissionAgencyPolicyCollection,
  CobrokePolicyCollection,
  SalespersonPolicyCollection,
  CommisionMatrixPolicyCollection,
  PromotionMatrixPolicyCollection,
  CaseSubmissionPersonalPolicyCollection,
  CommissionDistributionPolicyCollection,
  KYCPolicyCollection,
  SettingPolicyCollection,
  EcddPolicyCollection,
];

@NgModule({
  imports: [],
  declarations: [PermissionDirective, PolicyDirective, HasPolicyPipe],
  exports: [PermissionDirective, PolicyDirective, HasPolicyPipe],
  providers: [
    PermissionGuard,
    // PermissionService,
    PolicyGuard,
    PolicyService,
  ],
})
export class PermissionModule {
  constructor(
    private policyServices: PolicyService,
    private injector: Injector
  ) {
    const policies = this.injector.get(BasePolicyCollection) as any;
    this.policyServices.registerPolicyCollection(policies);
  }

  static forRoot<T extends BasePolicyCollection>(
    policies?: PolicyCollection<T>[] | PolicyProvider<T>[] | any[],
    config?: any
  ): ModuleWithProviders<PermissionModule> {
    return {
      ngModule: PermissionModule,
      providers: loadProviders(policies, true),
    };
  }

  static forChild<T extends BasePolicyCollection>(
    policies?: PolicyCollection<T>[] | PolicyProvider<T>[] | any[]
  ): ModuleWithProviders<PermissionModule> {
    return {
      ngModule: PermissionModule,
      providers: loadProviders(policies),
    };
  }
}

function loadProviders<T extends BasePolicyCollection>(
  policies: PolicyCollection<T>[] | PolicyProvider<T>[] | any[],
  loadDefaultPolicies = false
) {
  const providers: Provider[] = [];
  if (!policies && loadDefaultPolicies) {
    policies = registeredPolicies as PolicyCollection<T>[];
  }
  if (policies) {
    policies.forEach((policy) => {
      if (typeof policy === "object") {
        providers.push({
          provide: BasePolicyCollection,
          useClass: policy.policy,
          deps: policy.deps,
          multi: true,
        });
        policy.deps.forEach((dep) => {
          providers.push({
            provide: dep,
          });
        });
      } else {
        providers.push({
          provide: BasePolicyCollection,
          useClass: policy,
          multi: true,
        });
      }
    });
  }

  return providers;
}
